@use "sass:math";

@import "~@eika/core-style";

.hurtig-kjop-wrapper {
    position: relative;

    .graph-progress-wrapper {
    	margin-top: -20px;
    	position: relative;

	    .chart-text-wrapper {
	    	> div:first-child:before {
	    		display: none;
	    	}

	    	.chart-text {
	    		padding-bottom: $xtra-small;
	    		font-weight: normal;

	    		&:first-child {
		    		font-size: var(--font-size--xlarge);
	    		}

	    		&.-font-bold {
	    			font-weight: 700;
		    		font-size: var(--font-size--medium);
	    			margin-top: $xtra-small;
	    		}

	    		&.-font-normal {
		    		font-size: var(--font-size--regular);
		    		opacity: .8;

	    		}
	    	}
	    }
    }

    .sett-inn-penger-wrapper {
    	text-align: center;
    	margin-bottom: $large * 1.5;
    }

    .dine-fond-wrapper {
        margin-bottom: $large * 1.5;        
    	.card-wrapper {
    		&:before {
    			content: '';
    			position: absolute;
    			right: 10px;
    			top: 10px;
    			width: 30px;
    			height: 30px;
    			background: transparent url('../../../static/icon/arrow-right.svg') no-repeat center center / auto 80%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath class='cls-1' d='M20.92,12.38a1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-6-6a1,1,0,0,0-1.42,1.42L17.59,11H4a1,1,0,0,0,0,2H17.59l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l6-6A1,1,0,0,0,20.92,12.38Z'/%3E%3C/svg%3E");
    		}

    		.flex-text-holder.-index-0 {
    			margin-bottom: 0;
    		}
    		.flex-text-holder.-index-1 {
	    		font-size: var(--font-size--large);
    		}
    	}
    }

    .note-wrapper {
        margin-bottom: $large * 1.5;        
        .card-wrapper {
            background-color: #f7f4f2;
            &:before {
                content: '';
                position: absolute;
                right: 10px;
                top: 10px;
                width: 30px;
                height: 30px;
                background: transparent url('../../../static/icon/arrow-right.svg') no-repeat center center / auto 80%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath class='cls-1' d='M20.92,12.38a1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-6-6a1,1,0,0,0-1.42,1.42L17.59,11H4a1,1,0,0,0,0,2H17.59l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l6-6A1,1,0,0,0,20.92,12.38Z'/%3E%3C/svg%3E");
            }


            .flex-text-holder.-index-0 {
                margin-bottom: 0;
            }
            .flex-text-holder.-index-1 {
            }
        }
    }

    @media screen and (max-width: 350px) {
    }
}
