@import "~@eika/core-style";
@import "../../../styles/variables";


.storebrand-info-wrapper {
	position: relative;

	.-row {
		display: flex;
		gap: 4px;
		flex-wrap: wrap;
	}

	.storebrand-info-increasing-note {
		display: block;
		text-align: center;
		font-weight: 500;

		&.-desktop {
			display: inline-block;
			margin-bottom: $large * 1.5;

			&, .storebrand-info-increasing-note {
				text-align: left !important;
			}
		}

		.storebrand-info-increasing-note {
			font-weight: normal;
		}
	}

	.flex-text-holder .inner-text {
		display: block;
	}

	.text.-return-info .text {
		padding-right: 0 !important;
		text-align: right !important;
	}

	.summary-total-value-and-increasing-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		> div:last-child {
			padding-top: $smaller;
		}
		> div:first-child {
			padding-top: 0;
		}
	}

	.storebrand-info-increasing-wrapper .card-wrapper.-list-item {
		background-color: transparent;
	}

	.storebrand-info-saving-wrapper .card-wrapper.-list-item {
		background-color: transparent;
	}
}

.storebrand-risk-profile-wrapper {
	position: relative;
	.storebrand-risk-profile-note-holder {
		padding: $medium 0;

		> div:first-child {
			font-weight: 700;
			font-size: var(--font-size--medium);
		}
	}
}

.storebrand-agreement-wrapper {
	.employer-info-message .message-text:last-child {
		padding-top: $small;
		font-weight: 700;
	}

	.storebrand-agreement-payout-wrapper,
	.storebrand-agreement-arrangement-wrapper {
		padding-bottom: $large * 2;
	}
}
