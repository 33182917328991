@import "../../../../styles/variables";
$value-color: #00383D;
$label-color: #4D7477;
$pip-color: #97C5CA;

.savings-profile-info-box {
	display: flex;
	flex-direction: row;
	gap: $medium;
	margin-top: $smaller;
	.-item {
		display: flex;
		flex-direction: column;
		gap: $smallest;
		justify-content: space-between;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $value-color;
		.-label {
			font-size: 12px;
			line-height: 18px;
			color: $label-color;
			font-weight: 300;
		}
	}
}

.risk-level-indicator {
	display: flex;
	flex-direction: row;
	gap: $xxtra-small;
	padding-top: $tiny;
	.pip {
		width: $xtra-small;
		height: $xtra-small;
		border: solid 1px $pip-color;
		background: none;
		border-radius: $smaller;
		&.-filled {
			border: solid 1px $value-color;
			background: $value-color;
		}

	}
}

.change-profile-button-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: $medium-large;
}

.saving-profile-page, .switch-profile-page {
	.style--light {
		background-color: var(--theme-light);
	}
}
.switch-profile-page {
	.sgw-largelink-card {
		margin-top: $medium;
	}
}