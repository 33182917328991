@use "sass:math";

@import "~@eika/core-style";
@import "../../styles/mixin";
@import "../../styles/variables";

$tool-width: 460px;
$tool-bg-extra-width: 240px;
$adjustmentBtnSize: 40px;
$timeLineYearBtnSize: 30px;
$timeLineBarHeight: 24px;

@-webkit-keyframes bar-visible{
  0%   { width: 100%; }
  100% { width: 0; }
}

@keyframes bar-visible{
  0%   { width: 100%; }
  100% { width: 0; }
}

.kalkulator-wrapper {
    position: relative;
    padding: 0 0 $larger 0;

	*, *:before, *:after {
		box-sizing: border-box;
	}

    .relative {
    	position: relative;
    }

	.view-content {
		position: relative;
		display: table;
		width: 100%;
		padding: $large 0 $large $large;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: $tool-width + $tool-bg-extra-width;
			background: #F2F0EF none no-repeat 0 0;
			border-radius: 20px;
		}

		.tool-wrapper, .summary-wrapper {
			display: table-cell;
			vertical-align: top;
			position: relative;
			z-index: 1;
		}

		.tool-wrapper {
			position: relative;
			display: table-cell;
			width: $tool-width;

			.tool-holder {
				max-width: $tool-width - 100;
				min-width: 230px;
			}

			.sgw-attention-message {
				margin: $small $small $small 0;
			}
		}

		.summary-wrapper {
			position: relative;
			border-radius: 20px;
			background: #fff none no-repeat 0 0;
			display: table-cell;
			padding: $large;
			border: 2px solid #F2F0EF;

			/*
			@include boxLevelShadow();
			.panel-wrapper, .tool-search {
				display: none;
			}
			*/
		}
	}

	.input-wrapper {
		list-style: none;
		padding: 0;	
		margin: 0;

		li.input-holder {
			position: relative;
			margin: 0 0 $large 0; 

			&.-has-error input {
				border-color: #b83653;
			}

			&.-link,  &.-checkbox {
				.input-link-holder, 
				.input-content {
					margin-right: 0;
				}
			}

			&.-checkbox {
				padding-top: $xtra-small;
				label {
					padding-left: 0;
					font-weight: normal;
				}
			}

			&.-amount {
				label {
					padding-bottom: 0;
				}
			}

			label {
				padding-left: ($large * 1.2);
				padding-bottom: $xtra-small;
				font-weight: 600;
			}

			.input-link-holder,
			.input-content {
				position: relative;	
				margin: 0 ($adjustmentBtnSize * 1.2);
				z-index: 15;
			}

			.input-error {
				margin-top: $small;
				padding: 2px $xtra-small;
				background-color: var(--red-pastel);
				border-radius: 6px;
				color: var(--red);
			}

		    .adjustment-button {
		        position: absolute;
		        top: 50%;
		        width: $adjustmentBtnSize;
		        height: $adjustmentBtnSize;
		        margin-top: math.div($adjustmentBtnSize, 2) * -1;

		        &:before {
		            content: '';
		            position: absolute;
		            text-align: center;
		            font-weight: 700;
		            top: 50%;
		            left: 50%;
		            margin-left: (math.div(($large - 6), 2) * -1);
		            margin-top: (math.div(($large - 6), 2) * -1);
		            width: $large - 6;
		            height: $large - 6;
		            line-height: $large - 6;
		            background: var(--seagreen-dark) none no-repeat center center / auto 50%;
		            border-radius: 4px;
		            color: #fff;
		            border-radius: 100%;
		        }

		        &:hover, &:focus, &:active { 
		            &:before {
		                /*@include focusOutline(); */
		            }
		        }

		        &.-decrease {
		            left: 0;
		            &:before {
		                background-size: auto 90%;
		                background-image: url('../../static/icon/slider-minus.svg');
		                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5833 11.9168H6.41667C6.17355 11.9168 5.94039 11.8203 5.76849 11.6483C5.59658 11.4764 5.5 11.2433 5.5 11.0002C5.5 10.757 5.59658 10.5239 5.76849 10.352C5.94039 10.1801 6.17355 10.0835 6.41667 10.0835H15.5833C15.8264 10.0835 16.0596 10.1801 16.2315 10.352C16.4034 10.5239 16.5 10.757 16.5 11.0002C16.5 11.2433 16.4034 11.4764 16.2315 11.6483C16.0596 11.8203 15.8264 11.9168 15.5833 11.9168Z' fill='%23fff'/%3E%3C/svg%3E%0A");
		            }
		        }

		        &.-increase {
		            right: 0;
		            &:before {
		                background-image: url('../../static/icon/slider-plus.svg');
		                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5833 5.08333H6.91667V1.41667C6.91667 1.17355 6.82009 0.940394 6.64818 0.768486C6.47627 0.596577 6.24312 0.5 6 0.5C5.75689 0.5 5.52373 0.596577 5.35182 0.768486C5.17991 0.940394 5.08333 1.17355 5.08333 1.41667V5.08333H1.41667C1.17355 5.08333 0.940394 5.17991 0.768486 5.35182C0.596577 5.52373 0.5 5.75689 0.5 6C0.5 6.24312 0.596577 6.47627 0.768486 6.64818C0.940394 6.82009 1.17355 6.91667 1.41667 6.91667H5.08333V10.5833C5.08333 10.8264 5.17991 11.0596 5.35182 11.2315C5.52373 11.4034 5.75689 11.5 6 11.5C6.24312 11.5 6.47627 11.4034 6.64818 11.2315C6.82009 11.0596 6.91667 10.8264 6.91667 10.5833V6.91667H10.5833C10.8264 6.91667 11.0596 6.82009 11.2315 6.64818C11.4034 6.47627 11.5 6.24312 11.5 6C11.5 5.75689 11.4034 5.52373 11.2315 5.35182C11.0596 5.17991 10.8264 5.08333 10.5833 5.08333Z' fill='white'/%3E%3C/svg%3E%0A");
		            }
		        }
		    }

		    .amount-field-wrapper {
		    	&, label, .amount-field-holder {
			    	margin: 0;
			    	padding: 0;
		    	}

		    	label {
		    		padding-left: 0;
		    	}

		    	.sgw-input__input-mainlabel {
		    		display: none;
		    	}
		    	.sgw-input__input-label:before {
		    		display: none;
		    	}
			}

		    .unit {
				position: absolute;
				right: 0;
				top: -2px;
				padding: $small $small $small $xtra-small;
				z-index: 10;
				font-size: var(--font-size--medium);
				color: var(--seagreen-dark);
				min-height: 48px;
				line-height: 1.2;
		    }

		    .input-link-holder {
				margin-top: 0;
				display: table;
				> div {
					display: table-cell;
					vertical-align: middle;
					
					&.input-link-value {
						font-size: var(--font-size--large);
						padding-right: $small;
					}
				}

				+ .input-error {
					margin-top: 0;
				}	
		    }
		}
	}

	input, label {
		display: block;
		font-size: var(--font-size--regular);
		font-weight: normal;		
	}

	input[type="tel"], input[type="search"], input[type="text"] {
		border: 1px solid var(--seagreen-medium);
	    border-radius: 6px;
		padding: 0 ($medium + 40) 0 $medium;
		width: 100%;
		min-width: none;
		min-width: 0;
	    box-sizing: border-box;

	    &, + .unit {
			font-size: var(--font-size--medium);
			color: var(--seagreen-dark);
			min-height: 48px;
			line-height: 1.2;
	    }

		@include transition( all, .2s, linear);
	}

	.summary-wrapper {
		h2,	.summary-result-total, #summary-total-unit {
			text-align: center;
		}

		h2 {
			font-size: var(--font-size--large) !important;
		}

		.summary-content {
			max-width: 500px;
			margin: 0 auto;
		}
	}

	@media screen and (max-width: $maxDesktop) {
		.view-content {
			&, .tool-wrapper, .summary-wrapper {
				display: block;
			}

			.tool-wrapper .sgw-attention-message {
				margin: 0 0 $medium 0;
			}
		}
	}

	@media screen and (max-width: 600px) {
		.view-content {
			padding: 0;
			&:before {
				display: none;
			}

			.tool-wrapper {
				&, .tool-holder {
					width: auto;
					max-width: none;
				}

				.sgw-input__input {
					max-width: none !important;
				}
			}


			.summary-wrapper {
				box-shadow: none;
				border-radius: 0;
				border: 0 none;
				padding: $large $medium;
				/*
				margin: $large 0 0 0;
				border: 2px solid var(--seagreen-pastel);
				border-radius: 20px;
				*/

				.summar-time-line {
					margin-left: $medium * -1;
					.bar-wrappper:before {
						padding-left: $medium;
					}
				}
			}
		}
	}


	@media screen and (max-width: 500px) {
		.chart-wrapper {
			.axis-wrapper text {
				font-size: 26px !important;
			}
		}
	}
}

.pensjonsberegning-wrapper {
	#summary-total-unit {
		margin: $medium 0 0 0;
		font-weight: 600;
	}

	.summary-result-total {
		margin: 0 0 $medium 0;
		strong {
			font-size: var(--font-size--largest);
		}
	}

	.summary-result-textlist {
		.flex-text-holder {
			padding-bottom: $xtra-small;
		}

		.card-wrapper.-list-item {
			border: 0 none;
		}
	}

	.summary-result-sum {
		margin-top: -1px;
	}

	.summary-result-difference {
		margin-top: 1px;
	}

	ul.summar-time-line {
		list-style: none;
		padding: $medium 0;
		margin: 0;
		position: relative;

		.summar-time-line-item {
			position: relative;
			display: flex;
			flex: row;

			&:first-child .summar-time-line-year:after {
				content: 'År';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 100%;
				text-align: center;
			}

			&:last-child {
				.summar-time-line-bar {
					padding: 0;
				}
			}
		}

		.summar-time-line-year {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 50%;
				top: 0;
				bottom: 0;
				width: 2px;
				margin-left: -1px;
				background-color: var(--seagreen-dark);
			}

			> span {
				position: relative;
				display: inline-block;
				width: $timeLineYearBtnSize;
				height: $timeLineYearBtnSize;
				line-height: $timeLineYearBtnSize;
				border-radius: 100%;
				background-color: var(--seagreen-dark);
				color: #fff;
				text-align: center;
				z-index: 2;
			}
		}

		.summar-time-line-bar {
			position: relative;
			width: 100%;
			padding: ($timeLineYearBtnSize + 15) 0;
			margin-bottom: $timeLineYearBtnSize * -1;

			.summar-time-line-bar-cnt {
				position: relative;
			}
		}

		.bar-wrappper {
			position: relative;
			display: flex;
			flex: row;

			&:before {
				content: attr(title);
				position: absolute;
				bottom: 100%;
				left: 0;
			}


			&:after {
				content: '';
				position: absolute;
				right: 0;
				width: 100%;
				top: 0;
				bottom: 0;
				background-color: #fff;
		        @include animation( bar-visible, 1s, ease, .6s );
		        @include animation-forwards();
			}

			> span {
				height: $timeLineBarHeight;
				&:last-child {
					border-radius: 0 6px 6px 0;
				}
			}
		}
	}

	/*
	== ETTER NYTT DESIGN SKJULER DET ÅR TALLENE ==
	*/
	ul.summar-time-line {
		.summar-time-line-year {
			display: none;
		}
		.summar-time-line-bar {
			padding-bottom: $medium;
		}
	}

	@media screen and (max-width: 600px) {
		.view-content .summary-wrapper {
			margin: $large 0 0 0;
			border: 2px solid var(--seagreen-pastel);
			border-radius: 20px;

			.summar-time-line {
				margin-left: $medium * -1;
				.bar-wrappper:before {
					padding-left: $medium;
				}
			}
		}
	}
}

.pensjonskalkultor-beregning-wrapper {
    position: relative;

    .pensjonskalkultor-beregning-point-list {
        margin-left: -14px;
        > li {
            padding-bottom: $small;
        }
    }

    .pensjonskalkultor-beregning-note {

    }
}

.pensjonskalkultor-fund-development-info-wrapper{
	position: relative;

	.fund-development-info-description {
		border: 1px solid red;
		display: none;	
	}

	.fund-development-chart-description {
		list-style: none;
		padding: 0;
		display: table;
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		> li {
			display: table-row;

			> div {
				position: relative;
				display: table-cell;
				white-space: nowrap;
				font-weight: 700;
				padding: $xtra-small 0;
				text-align: right;
				vertical-align: top;

				&:first-child {
					font-weight: normal;
					text-align: left;
					width: 100%;
					white-space: normal;
					padding-left: $medium;
					padding-right: $small;
				}

				.dot {
					position: absolute;
					left: 0;
					top: 12px;
					width: $small;
					height: $small;
					border-radius: 100%;
					margin: 0 $xtra-small -2px 0;
				}
			}
		}

	}
}

.fondsberegning-wrapper {
	table {
	    border-collapse: collapse;
	    border-spacing: 0;
	    width: 100%;
	   	padding: 0;
	   	margin: $medium 0;

	    caption {
	    	@include ariaVisible();
	    }

	    tr {
	        padding: 0;
	        td, th {
	            padding: $xtra-small;
	            vertical-align: top;
	            text-align: right;
	            border-bottom: 1px solid var(--grey-medium);
				white-space:  nowrap;

	            &:first-child {
	            	padding-left: 0;
	            	text-align: left;
	            }

	            &:last-child {
	            	padding-right: 0;
	            }
	        }

	        th {
	        	span {
	        		display: block;
	        	}
	        }
	    }
	}

	.result-info-list {
		text-align: center;
		margin: $medium 0;
		display: flex;
		flex-direction: column;

		> li {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}

		.result-info-item {
			position: relative;
			margin: 0;
			padding: 0 0 $medium 0;
			text-align: left;

			> div {
				position: relative;
				text-align: left;
				&:first-child {
					margin-bottom: ($smallest + 4) * -1;
				}

				&.error-holder {
					margin-top: $smaller;
				}
			}

			.point {
				display: inline-block;
				width: $small;
				height: $small;
				margin-bottom: -2px;
				border-radius: 100%;
				margin-right: $smallest + 4;
			}

			strong {
				font-size: var(--font-size--largest);
			}
		}
	}
}

.fondsberegning-selected-fund-wrapper {
	padding: 0 ($large + $xtra-small);

	.fondsberegning-selected-fund-title {
		font-size: var(--font-size--large);
		color: var(--seagreen-dark);
		font-weight: 400;
		padding: 0 0 $small 0;
	}

	.fondsberegning-selected-fund-list li {
		display: block;
		overflow: hidden;
		position: relative;
		border-radius: $border-round;
		padding: $medium $small;
		margin: 0 0 $small 0;

		&.banksparing .selected-item {
			padding-right: 0;
			a {
				display: none;
			}
		}

		.fondsberegning-selected-fund-item-title {
			font-size: var(--font-size--medium);
			font-weight: 600;			
			margin: 0;
		}

		.fondsberegning-selected-fund-item-description {
			margin: 0 0 $xtra-small 0;
		}

		.selected-item {
			position: relative;
			display: inline-block;
			padding-right: ($medium + 2);
			margin: $xtra-small $small 0 0;
			border-style: solid;
			border-width: 0 0 2px 0;

			strong {
				font-weight: 600;
			}

		    a.closer {
				position: absolute;
				right: 0;
				top: 0;

				&, &:before {
					width: $medium;
					height: $medium;
					line-height: $medium;
		            text-align: center;
				}

		        &:before {
		            content: '\002715';
		            color: inherit;
		            font-size: 18px;
		        }
		    }
		}
	}


	@media screen and (max-width: 600px) {
		padding: 0;

		.fondsberegning-selected-fund-title {
		}
	}
}


/******************************************************************************
font scela
******************************************************************************/
.font-scale-large .pensjonsberegning-wrapper {
}
