@import "~@eika/core-style";
@import "../../styles/variables";

.portefolje-wrapper {
    position: relative;

    .tab-list-wrapper {
    	margin-bottom: $medium;
    }

    .info-item.-innskudd {
        display: none;
    }
}

.-header-inline{ text-align: left}

.wallpaper-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    padding: $medium 0
}

.wallpaper-header-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: $medium;

    .button-float-right-wrapper {} .link-list-wrapper > ul > li {
        margin-right: 0;
        margin-top: calc($medium * -1);
    }
}

.max-portfolio-width {
    max-width: 600px;
}

.fund-switch-wrapper {
    @media screen and (min-width: $desktop) {
        margin-top: $medium;
        width: fit-content;
        min-width: 60%
    }
}