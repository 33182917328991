@import "../../../styles/animation";
@import "../../../styles/mixin";
@import "./variables";


@-webkit-keyframes rotation {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(0deg); }
    90% { transform: rotate(360deg); }
    100% { transform: rotate(360deg); }
}

@keyframes rotation {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(0deg); }
    90% { transform: rotate(360deg); }
    100% { transform: rotate(360deg); }
} 

$spinnerSize:           68px;

$xxtra-small:           4px;
$xtra-small:            8px;
$smaller:                12px;
$small:                 16px;
$medium:                24px;
$large:                 40px;
$larger:                $medium * 2;

$color-seagreen-pastel: #97c5ca;
$color-seagreen-dark:   #00383d;
$color-white:           #fff;

$font-family:          "Open Sans", Arial, sans-serif;
$font-color:            $color-seagreen-dark;

$border-round-button:   24px;


.ekf-spare-linking-wrapper {
    text-align: center;
    white-space: nowrap;
    background-color: $color-white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000000;
    margin: 0;
    padding: $larger $medium;
    font-size: 18px;

    .loader {
        position: relative;
        &:before {
            content: '';
            display: inline-block;
            width: $spinnerSize;
            height: $spinnerSize;
            border-width: $spinnerSize * .13;
            border-style: solid;
            border-color: #cfc5bd #97c5ca #00383d #c2e375;
            border-radius: 100%;
            @include animation( rotation, 1.4s, ease-in-out, 0);
            @include animation-repeat();    
        }

        &:after {
            content: 'Vent litt';
            display: block;
            font-weight: 600;
        }
    }

    .paragraph {
    	padding: 0 0 24px 0;
    }


    button.mb-closer {
        position: relative;
        font-size: $font-size-regular;
        font-weight: normal;
        font-family: $font-family;
        background: $color-seagreen-dark none no-repeat 0 0;
        border: 2px solid $color-seagreen-dark;
        padding: $smaller $medium;
        border-radius: $border-round-button;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        border: 0 none;
        text-decoration: none;
        cursor: pointer;

        &, &:hover, &:focus, &:visited {
            color: $color-white;
            text-decoration: none;
        }

        &:focus {
            outline: $color-seagreen-dark solid 1px;
            outline-offset: 4px;    
        }
    }
}
