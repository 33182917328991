@import "~@eika/core-style";
@import "../../styles/mixin";
@import "../../styles/variables";

.kalkulator-wrapper {

	#dialog-box-title,
	.fund-overview-wrapper {
		margin-left: $large * -1.5;
		margin-right: $large * -1.5;

		.fund-filter-button {
			display: none;
		}
	}
}