@use "sass:math";

@import "~@eika/core-style";
@import "./variables";

/******************************************************************************
 === TRANSITION ===
******************************************************************************/
@mixin transition( $property, $duration, $timing, $delay: 0s ) {
    -webkit-transition: $property $duration $timing $delay;
    -moz-transition: $property $duration $timing $delay;
    -o-transition: $property $duration $timing $delay;
    -ms-transition: $property $duration $timing $delay;
    transition: $property $duration $timing $delay;
}

@mixin noneTransition() {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
}

/******************************************************************************
 === ANIMATION ===
******************************************************************************/
@mixin animation( $property, $duration, $timing, $delay: 0s ) {
    -webkit-animation: $property $duration $timing $delay;
    -moz-animation: $property $duration $timing $delay;
    -o-animation: $property $duration $timing $delay;
    -ms-animation: $property $duration $timing $delay;
    animation: $property $duration $timing $delay;
}

@mixin noneAnimation() {
    -webkit-animation: none;
    -moz-animation: none;
    -o-animation: none;
    -ms-animation: none;
    animation: none;
}

@mixin animation-forwards() {
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;   
}

@mixin animation-repeat( $count: infinite) {
    -moz-animation-iteration-count: $count;
    -o-animation-iteration-count: $count;
    -webkit-animation-iteration-count: $count;
    animation-iteration-count: $count;   
}

@mixin animation-delay( $count: 0) {
    -moz-animation-delay: $count;
    -o-animation-delay: $count;
    -webkit-animation-delay: $count;
    animation-delay: $count;   
}

@mixin animation-duration( $count: 0) {
    -moz-animation-duration: $count;
    -o-animation-duration: $count;
    -webkit-animation-duration: $count;
    animation-duration: $count;   
}

/******************************************************************************
******************************************************************************/

@mixin opacity( $property ) {
    opacity: $property;
    filter: alpha(opacity= ($property*100));
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}

@mixin ariaVisible() {
    position: absolute;
    left: 0;
    top: 0;
    opacity: .1;
    filter: alpha(opacity=1);
    color: transparent;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@mixin normalVisible() {
    position: relative;
    left: auto;
    top: auto;
    opacity: 1;
    filter: alpha(opacity=100);
    color: inherit;
    width: auto;
    height: auto;
    overflow: visible;
}

/******************************************************************************
******************************************************************************/
@mixin pageHeaderSpareImage(){
    content: '';
    display: block;
    height: 100px;
    background: transparent none no-repeat center center / auto 90%;
    margin: $medium 0;
}

/******************************************************************************
******************************************************************************/
@mixin card(){
    position: relative;
    padding: $small;
    margin: 0;
    border-radius: $xtra-small;
    background-color: #fff;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.18);
    /* @include boxShadow(); */
}

@mixin cardFocus(){
    box-shadow: 0px 1px 6px 1px var(--seagreen-dark);
}

/******************************************************************************
 === shadow ===
******************************************************************************/
@mixin boxShadow( $opacityAlfa:.1, $opacityBeta:.12 ){
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, $opacityAlfa), 0 1px 2px 0 rgba(0, 0, 0, $opacityBeta);
}

@mixin boxLevelShadow( $opacityAlfa:.1, $opacityBeta:.1 ){
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, $opacityAlfa), 0 5px 6px 0 rgba(0, 0, 0, $opacityBeta);
}

@mixin boxPopupShadow(){
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 15px 12px 0 rgba(0, 0, 0, 0.1);
}

@mixin focusShadow( $color:#00383d, $size:2px ){
    border-color: $color;
    box-shadow: 0 0 0 $size $color;
    outline: none;
}

@mixin errorShadow( $color:#e60000 ){
    border-color: $color;
    box-shadow: inset 0 0 2px $color;
    outline: none;
}

@mixin focusOutline( $color:#00383d ){
    outline: 2px solid $color;
    outline-offset: 3px;
}

/******************************************************************************
 === progress bar in percentage ===
 green: #66BC79
 red: #C5686A
******************************************************************************/
@mixin progressRadial( $size: 180px, $stroke: 10, $barColor: #66BC79, $bg: #ECE5E0, $dbg: #FFF ) {
    $bdiam: math.div($size, 3) - 10;

    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;
    width: $size;
    height: $size;
    border-radius: 100%;  
    border: 0 none;
    background-color: $barColor;

    > span {
        &:before, &:after {
            color: #333;
            text-align: center;     
            position: absolute;
            left: 50%;
            top: 50%;
            font-weight: 900;
        }

        &:before {
            content: '';
            width: $size - $stroke;
            height: $size - $stroke;
            margin: (math.div(($size - $stroke), 2)*-1) 0 0 (math.div(($size - $stroke), 2)*-1);
            border-radius: 100%;
            background: $dbg none repeat 0 0;
        }

        &:after{
            background: transparent none repeat 0 0;
            position: absolute;
            width: 50px;
            height: 50px;
            margin: -25px 0 0 -25px;
            line-height: 48px;
            font-size: 20px;
            display: none;
        }
    }
    
    $step: 1; 
    $loops: round(math.div(100, $step));
    $increment: math.div(360, $loops);
    $half: round(math.div($loops, 2));
    @for $i from 0 through $loops {
        &.-percent-#{$i*$step} {
            &:before {
                transform: rotate(#{$i*3.6}deg) translate(0,(math.div($size,2)*-1) + math.div($bdiam,2));
            }

            > span:after {
                content: '#{$i}%';
                display: none;
            }
            @if $i < $half {
                $nextdeg: 90deg + ( $increment * $i );
                background-image: 
                linear-gradient(90deg, $bg 50%, transparent 50%, transparent), 
                linear-gradient($nextdeg, $barColor 50%, $bg 50%, $bg);
            } @else {
                $nextdeg: -90deg + ( $increment * ( $i - $half ) );
                background-image: 
                linear-gradient($nextdeg, $barColor 50%, transparent 50%, transparent),
                linear-gradient(270deg, $barColor 50%, $bg 50%, $bg);
            }
        }
    }
}
