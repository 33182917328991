@import "~@eika/core-style";
@import "../../../styles/variables";

.arbeidsgiver-risikoprofil-wrapper {
    position: relative;

    .price-section .card-wrapper{
        &:first-child {
            border-top: 0 none;
        }
        &:last-child {
            border-bottom: 0 none;
        }
    }

    .info-text {
        padding: 0 0 $medium 0;
    } 
}
