@use "sass:math";

@import "~@eika/core-style";
@import "../../styles/variables";

.service-message-wrapper {
    max-width: 600px;
    padding-bottom: $medium;

    .message-wrapper {
        margin-bottom: $medium;

        h1, h2, h3 {
            font-size: var(--font-size--large) !important;
            padding-bottom: $small !important;
        }
    }
}
