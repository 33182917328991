@import "~@eika/core-style";

.draw-day-table-wrapper {
    position: relative;

    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 $large 0;

        > li {
            position: relative;
            text-align: center;
            display: inline-block;
            width: 14.28%;
            &:after {
                content: '';
                display: block;
                margin-top: 100%;
            }
        }
    }   

    a.day-btn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: block;
        text-align: center;

        &:before {
            content: '';
            display: block;
            margin-top: 30%;
        }        

        &.-active {
            font-weight: 700;
            > span {
                border-bottom-color: var(--seagreen-light);
            }
        }

        > span {
            display: inline-block;
            border-bottom: 2px solid transparent;
        }
    }
}
