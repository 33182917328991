@import "~@eika/core-style";
@import "../../styles/mixin";
@import "../../styles/variables";

.fond-visning-wrapper {
    position: relative;

    .fund-overview-wrapper {
        margin: 0 ($medium * -1);
    }

    .grid-layout-wrapper.sparing-header.-empty-header {
        margin-bottom: $large * -1;
    }


    .fond-visning-content.right-appending.-show-detail-modal,
    .fond-visning-content.left-appending.-show-detail-modal {
        position: relative;
        z-index: 100;
    }
}

/******************************************************************************
font scela
******************************************************************************/
.font-scale-large .ordre-wrapper {
}

.ordre-platform-pricelist {
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: left;
}
