@import "~@eika/core-style";

.signing-iframe-wrapper {
    position: relative;

    iframe.signing-frame {
        height: 282px; // signing content max height
        width: 100%;
    }
}
