@use "sass:math";

@import '../../styles/variables';
@import '../../styles/mixin';

$slider-color-btn: #4D7477;
$slider-color-btn-disabled: #4D7477;
$track-color: #eee;
$btn-size: 33px;
$adjustment-btn-size: 44px;
$adjustment-btn-size-delta: 16px;

@mixin amountFieldUnit() {
    @for $i from 0 through 28 {
        &.-length-#{$i} {
            .amount-field-holder label:before {
                $chr: '\00a0 \00a0';
                $content: '\00a0 \00a0';
                @for $j from 0 to $i {
                    $content: $content + $chr;
                }
                content: "#{$content}+\00a0  kr";
                margin-left: -7px;
            }
        }
    } 
}

.slider-wrapper {
    position: relative;
    overflow: visible;
    padding: 0 2px 7px 2px;
    margin: 0;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        /*
        left: -200px;
        right: -200px;
        */
        top: $btn-size * -2.5;
        bottom: $btn-size * -2;;
        display: none;
    }

    &.-on-sliding {
        overflow: visible;
        &:before {
            display: block;
        }

        .slider-track .slider-btn:after {
            margin-bottom: 15px;
        }
    }

    &.-show-slider-btn-current-value .slider-track .slider-btn:after{
        display: block;
    }

    &.-has-adjustment-button {
        .slider-track {
            margin-left: $adjustment-btn-size + math.div($btn-size, 2);
            margin-right: $adjustment-btn-size + math.div($btn-size, 2);
        }
    }

    &.-has-field {
        .slider-track .slider-btn:after {
            display: none;
        }
    }

    input[type="range"] {
        @include ariaVisible();

        &:hover, &:focus, &:active { 
            box-shadow: 0px 1px 6px 1px var(--seagreen-dark);
            + .slider-track .slider-btn {
                @include focusOutline();
            }
        }
    }

    .slider-holder {
        position: relative;
        margin-top: $btn-size * 2;
    }

    .slider-track {
        position: relative;
        z-index: 10;
        margin: 40px math.div($btn-size, 2);
        /* Block area from manipulation actions (zoom, pan) */
        /*-ms-touch-action: none;*/
        -ms-touch-action: pan-y;

        &, &:before, &:after, .slider-tail, .slider-tail:before {
            position: relative;
            height: 4px;
            background-color: $track-color;
            border-radius: $border-round-button;
        }

        &:before, &:after, .slider-tail:before {
            content: '';
            position: absolute;
            left: math.div($btn-size, 2) * -1;
            right: math.div($btn-size, 2) * -1;
            top: 0;
        }

        &:after {
            height: 40px;
            top: -20px;
            border-radius: 0;
            background-color: transparent;
        }

        .slider-tail, .slider-btn {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }

        .slider-btn {
            text-align: center;
            font-weight: bold;
            border-radius: 100%;
            border: 2px solid $slider-color-btn;
            z-index: 3;
            background: #fff  url('../../static/icon/slider-btn.svg') no-repeat center center / auto 90%;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='8.5' y='8.5' width='1' height='9' rx='0.5' fill='%234D7477' stroke='%234D7477'/%3E%3Crect x='12.5' y='8.5' width='1' height='9' rx='0.5' fill='%234D7477' stroke='%234D7477'/%3E%3Crect x='16.5' y='8.5' width='1' height='9' rx='0.5' fill='%234D7477' stroke='%234D7477'/%3E%3C/svg%3E%0A");
            text-indent: -5000px;
            width: $btn-size;
            height: $btn-size;
            margin: (math.div($btn-size,2) * -1) 0 0 (math.div($btn-size,2) * -1);

            /* @include boxLevelShadow(); */

            &:hover, &:focus {
                /* @include focusOutline(); */
            }

            &:before {
                content: '';
                position: absolute;
                left: -20px;
                right: -20px;
                top: -20px;
                bottom: -20px;
            }

            &:after {
                content: attr(data-value) " " attr(data-unit);
                position: absolute;
                bottom: 100%;
                left: 50%;
                width: 150px;
                margin: 0 0 0 -75px;
                padding: 0 0 2px 0;
                border-radius: $border-round;
                font-size: 125%;
                color: var(--seagreen-dark);
                text-align: center;
                z-index: 10000;
                text-indent: 0;
                display: none;
                @include transition( all, .2s, ease-in-out);
            }

            &.-disabled {
                background-color: $slider-color-btn;
            }
        }

        .slider-tail {
            &, &:before {
                background-color: $slider-color-btn;
            }
        }
    } 

    .slider-label {
        position: absolute;
        top: 100%;
        margin-top: 20px;

        &.-minimum {
            left: -18px;
        }
        &.-maximum {
            right: -18px;
        }
    }

    .adjustment-button {
        position: absolute;
        top: 0;
        width: $adjustment-btn-size;
        height: $adjustment-btn-size;
        margin-top: math.div($adjustment-btn-size, 2) * -1;

        &:before {
            content: '';
            position: absolute;
            text-align: center;
            font-weight: 700;
            top: 50%;
            margin-top: (math.div(($adjustment-btn-size - $adjustment-btn-size-delta), 2) * -1) + 3;
            width: $adjustment-btn-size - $adjustment-btn-size-delta;
            height: $adjustment-btn-size - $adjustment-btn-size-delta;
            line-height: $adjustment-btn-size - $adjustment-btn-size-delta;
            background: var(--seagreen-dark) none no-repeat center center / auto 50%;
            border-radius: 4px;
            color: #fff;
            border-radius: 100%;
        }

        &:hover, &:focus, &:active { 
            &:before {
                @include focusOutline();
            }
        }

        &.-index-0 {
            left: ($adjustment-btn-size + math.div($btn-size, 2)) * -1;
            &:before {
                left: 0;
                background-size: auto 90%;
                background-image: url('../../static/icon/slider-minus.svg');
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5833 11.9168H6.41667C6.17355 11.9168 5.94039 11.8203 5.76849 11.6483C5.59658 11.4764 5.5 11.2433 5.5 11.0002C5.5 10.757 5.59658 10.5239 5.76849 10.352C5.94039 10.1801 6.17355 10.0835 6.41667 10.0835H15.5833C15.8264 10.0835 16.0596 10.1801 16.2315 10.352C16.4034 10.5239 16.5 10.757 16.5 11.0002C16.5 11.2433 16.4034 11.4764 16.2315 11.6483C16.0596 11.8203 15.8264 11.9168 15.5833 11.9168Z' fill='%23fff'/%3E%3C/svg%3E%0A");
            }
        }

        &.-index-1 {
            right: ($adjustment-btn-size + math.div($btn-size, 2)) * -1;
            &:before {
                right: 0;
                background-image: url('../../static/icon/slider-plus.svg');
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5833 5.08333H6.91667V1.41667C6.91667 1.17355 6.82009 0.940394 6.64818 0.768486C6.47627 0.596577 6.24312 0.5 6 0.5C5.75689 0.5 5.52373 0.596577 5.35182 0.768486C5.17991 0.940394 5.08333 1.17355 5.08333 1.41667V5.08333H1.41667C1.17355 5.08333 0.940394 5.17991 0.768486 5.35182C0.596577 5.52373 0.5 5.75689 0.5 6C0.5 6.24312 0.596577 6.47627 0.768486 6.64818C0.940394 6.82009 1.17355 6.91667 1.41667 6.91667H5.08333V10.5833C5.08333 10.8264 5.17991 11.0596 5.35182 11.2315C5.52373 11.4034 5.75689 11.5 6 11.5C6.24312 11.5 6.47627 11.4034 6.64818 11.2315C6.82009 11.0596 6.91667 10.8264 6.91667 10.5833V6.91667H10.5833C10.8264 6.91667 11.0596 6.82009 11.2315 6.64818C11.4034 6.47627 11.5 6.24312 11.5 6C11.5 5.75689 11.4034 5.52373 11.2315 5.35182C11.0596 5.17991 10.8264 5.08333 10.5833 5.08333Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }


    .slider-field-wrapper {
        list-style: none;
        padding: 0;
        margin: 0 0 $small 0;
        display: table;
        width: 100%;
        max-width: 400px;

        &.-count-2 > li {
            width: 48%;
            padding: 0 0 0 2%;
            &:first-child {
                padding: 0 2% 0 0;                
            }
        }

        > li {
            display: table-cell;
            width: 100%;
        }
    }

    .slider-field-holder {
        position: relative;
        border: 1px solid #4D7477;
        border-radius: 6px;
        padding: $small 0 0 0;

        &.-disalbed:before {
            content: '';
            position: absolute;
            right: $small;
            top: 50%;
            width: 24px;
            height: 24px;
            margin: -12px 0 0 0;
            z-index: 5;
            background: transparent url('../../static/icon/lock.svg') no-repeat center center / auto 100%;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.14V7C17 5.67392 16.4732 4.40215 15.5355 3.46447C14.5979 2.52678 13.3261 2 12 2C10.6739 2 9.40215 2.52678 8.46447 3.46447C7.52678 4.40215 7 5.67392 7 7V8.14C6.14352 8.36114 5.38451 8.85993 4.84169 9.55836C4.29887 10.2568 4.00287 11.1154 4 12V18C4 19.0609 4.42143 20.0783 5.17157 20.8284C5.92172 21.5786 6.93913 22 8 22H16C17.0609 22 18.0783 21.5786 18.8284 20.8284C19.5786 20.0783 20 19.0609 20 18V12C19.9971 11.1154 19.7011 10.2568 19.1583 9.55836C18.6155 8.85993 17.8565 8.36114 17 8.14ZM9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7V8H9V7ZM18 18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H8C7.46957 20 6.96086 19.7893 6.58579 19.4142C6.21071 19.0391 6 18.5304 6 18V12C6 11.4696 6.21071 10.9609 6.58579 10.5858C6.96086 10.2107 7.46957 10 8 10H16C16.5304 10 17.0391 10.2107 17.4142 10.5858C17.7893 10.9609 18 11.4696 18 12V18Z' fill='%2300383D'/%3E%3Cpath d='M12 12C11.4696 12 10.9609 12.2107 10.5858 12.5858C10.2107 12.9609 10 13.4696 10 14C10.0021 14.349 10.0955 14.6914 10.2709 14.9932C10.4464 15.2949 10.6977 15.5455 11 15.72V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V15.72C13.3023 15.5455 13.5536 15.2949 13.7291 14.9932C13.9045 14.6914 13.9979 14.349 14 14C14 13.4696 13.7893 12.9609 13.4142 12.5858C13.0391 12.2107 12.5304 12 12 12Z' fill='%2300383D'/%3E%3C/svg%3E%0A");
        }

        &.-on-max .amount-field-wrapper {
            @include amountFieldUnit();
        }

        .amount-field-wrapper {
            padding: 0;
            margin: 0;
        } 

        .sgw-input__input-mainlabel {
            padding: 0 0 0 $medium !important;
            margin: 0 0 -10px 0 !important;
            font-weight: normal !important;
            position: relative;
            z-index: 20;
            font-size: var(--font-size--small) !important;
        }

        input {
            border: 0 none !important;
            font-weight: 700 !important;
            margin: 0 !important;
        }        
    }
}






