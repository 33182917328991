@use "sass:math";

@import "~@eika/core-style";

$maxWidthSmall: 800px;
$bar-bg-color: #F3F0EE;
$bar-active-color: #fff;

.tab-list-wrapper {
    max-width: $maxWidthSmall;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    white-space: nowrap;
    background-color: $bar-bg-color;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    border: 2px solid $bar-bg-color;

    &, a.tab-btn {
        border-radius: $medium;
    }

    &.-small {
        a.tab-btn {
            padding-top: math.div($small, 4);            
            padding-bottom: math.div($small, 4);            
        }
    }

    > li {
        padding: 0;
        text-align: center;
        width: 100%;
    }

    a.tab-btn {
        display: block;
        background: transparent none no-repeat 0 0;
        font-weight: 600;
        text-align: center;
        font-size: var(--font-size-regular);
        padding: math.div($medium, 2);
        border: 1px solid transparent;

        &.-active {
            background-color: $bar-active-color;
            border-color: var(--segreen-medium);
        }
    }
}
