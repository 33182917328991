@use '~@eika/core-style' as core;

.sgw-product-info-card {
	border-radius: 12px;
	border: 2px solid var(--theme-dark);
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.sgw-product-info-card__wrapper {
	padding: 24px;
	display: flex;
	flex-direction: column;

	& > :last-child {
		margin-bottom: 0;
	}
}

.sgw-product-info-card__label {
	border-radius: 10px 10px 0 0;
	background-color: var(--theme-light);
	padding: 24px;

	@include core.typography-subtitle-mixin;
}

.sgw-product-info-card__description {
	margin-bottom: 24px;
}

.sgw-product-info-card__highlighted-note {
	margin: 0 0 0 24px;
	font-weight: core.$font-weight-semibold;
	padding: 4px 16px;
	background: var(--accent-color, var(--theme-medium));
	display: inline-block;
	border-radius: 6px 6px 0 0;

	p {
		margin: 0;
	}
}