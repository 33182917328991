@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";
@import "../../styles/animation";

$digitSize: $large * 1.4;
$digitSpace: $digitSize * .15;
$digitWidth: $digitSize * .7;
$digitHeight: $digitSize;

@-webkit-keyframes digit-visible {
 	0%   { visibility: visible; }
 	10%  { visibility: hidden;  }
 	100% { visibility: hidden;  }
};

@keyframes digit-visible {
 	0%   { visibility: visible; }
 	10%  { visibility: hidden;  }
 	100% { visibility: hidden;  }
};

@-webkit-keyframes cloud-moving {
 	0%   { margin-top: -15px; }
 	50% { margin-top: 15px; }
 	100%   { margin-top: -15px; }
};

@keyframes cloud-moving {
 	0%   { margin-top: -15px; }
 	50% { margin-top: 15px; }
 	100%   { margin-top: -15px; }
};

.countdown-wrapper {
	position: relative;
	text-align: center;
	padding: 0 0 $medium 0;

	.countdown-image-wrapper {
		position: relative;
		max-width: 400px;
		margin: $large auto $small auto;

		.countdown-image-cloud-face {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			margin-top: -20px;
			@include animation( cloud-moving, 4s, ease );
			@include animation-repeat();			
		}
	}

	.finish-message {
		position: relative;
		font-weight: 600;
	}

	ul.countdown-holder {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		text-align: center;
		justify-content: center;

		> li {
			position: relative;
			
			&, > span {				
				width: $digitWidth;
				height: $digitHeight;
				line-height: $digitSize;
				font-size: $digitSize - $digitSpace;
				font-weight: 700;
			}

			> span {
				display: inline-block;
				visibility: hidden;
				position: absolute;
				left: 0;
				top: 0;
				@include animation( digit-visible, 10s, linear );
				@include animation-repeat();

				&.tenth-place {
					@include animation( digit-visible, 100s, linear );
					@include animation-repeat();
				}

				&.hundred-place {
					@include animation( digit-visible, 1000s, linear );
					@include animation-repeat();
				}

			    &.-none-animation {
			    	@include noneAnimation();
			    }

			    @for $i from 0 through 1000 {
			    	&.-delay-#{$i} {
			    		@include animation-delay(#{$i}s);
			    	}
			    }

			    @for $i from 0 through 1000 {
			    	&.-begin-#{$i} {
			    		visibility: visible;
			    		@include animation-duration(#{$i}s);
						@include animation-repeat(1);
			    		@include animation-forwards();
			    	}
			    }
			}
		}
	}
}
