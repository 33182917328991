@media screen and (max-width: 767px) {
    .list-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        height: 50px;
    }
    .header-el-length-1 {
        visibility: hidden;
    }

    .header-el-length-2 {
        width: 100%;
    }

    .header-el-length-3 {
        visibility: hidden;
    }
}

@media screen and (min-width: 767px) {
    .list-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
    }
    .header-el-length-1 {
        width: 15%;
    }

    .header-el-length-2 {
        width: 35%;
    }
    .header-el-length-3 {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
}
