$color-link-dark: #34AFD9;
$color-link: #97C5CA;
$color-eika-light: #D3EC99;
$color-eika: #84bd00;
$color-eika-dark: #90D000;
$color-seagreen: #004f59;
$color-seagreen-medium:  #4d7477;
$color-seagreen-dark: #00383d;
$color-seagreen-light: #e6f3f5;
$color-bullet: $color-link;
$color-border: #4D7477;
$color-focus: #34AFD9;

$xtra-small: 6px;
$small: 12px;
$regular: 16px;
$medium: 24px;
$large: 36px;

$border-radius: 4px;
$border-round-card: 6px;
$border-round: 2px;

$font-size-largest: 30px;
$font-size-large: 24px;
$font-size-medium: 20px;
$font-size-regular: 16px;
$font-size-small: 14px;
$font-size-smallest: 12px;
