@import "~@eika/core-style";

$larger: $medium * 2;
$returnPieBasic: $larger;
$returnPieFundDevelopment: $larger + $xtra-small;

$maxDesktop:            1024px;
$desktop:               768px;
$maxWidthSmall:         520px;
$maxWidthMedium:        $desktop;
$maxWidthLarge:         $maxDesktop;
$maxWidthLargest:       1200px;
$minWidth:              350px;

$gridWidth:             100px;

$border-round:          6px;
$border-radius:         12px;
$border-card:           16px;
$border-round-button:   25px;

/*******************************************************************/
$color-brand:           #84BD00;
$color-primary:         #84BD00;   /*76a300*/
$color-primary-light:   #BCDB73;
$color-secondary:       #61C3E6;  /*#32374b;*/
$color-warning:         #ffc800;
$color-error:           #a92424;

$color-border-dark:     #333333;
$color-border:          #e2ddda;
$color-border-light:    rgba(177, 162, 151, 0.45);
$color-border-green:    #00383D;

$color-focus:           #84BD00; /*#0874BF; #0874BF;*/
$color-active:          #76a300; /*#0874BF;*/
$color-action:          #318500;
$color-link:            #2872EB;
$color-spinner:         #84BD00;


$color-info:            #61C3E6;
$color-success:         #84BD00;
$color-warning:         #F7C288;
$color-danger:          #FBACC0;
$color-blocked:         #edd0e5;
$color-pending:         #FFF5D8;

$color-white:           #FFFFFF;

$color-red:             #FF0000;
$color-grey:            #ccc;
$color-grey-dark:       #999;
