@use "sass:math";

@import "~@eika/core-style";
@import '../../styles/mixin';
@import "../../styles/variables";

$progressRadialSize: $returnPieFundDevelopment;
$progressRadialView: 10;

.fund-detail {
	text-align: center;
	margin: 0 auto $large auto;
	max-width: 600px;

	.type-info .message-text {
		text-align: left;
	}

	> div, > ul, > section {
		margin-bottom: $large;
		h2 {
			font-weight: 600 !important;
		}
	}

	> p {
		margin: $xtra-small 0;
		&.lead + .lead {
			margin-top: $xtra-small * -1;
		}
	}


	.fund-return-panel {
		position: relative;
		display: table;
		width: 100%;
		padding: 0 0 $small 0;

		.chart-wrapper {
			margin: 0 ($small * -1);
		}

		.view-option-wrapper {
			width: auto;
			display: table;

			legend {
				font-weight: 400;
				text-align: left;
			}

			button {
				padding-left: $xtra-small;
				padding-right: $xtra-small;
			}
		}
	}

	ul.external-link-list {
		text-align: left;
		> li {
			padding-bottom: $small;
			span>a {
				display: flex;
			}
		}
	}

	h2 {
		text-align: left;
		+ p {
			text-align: left;
		}
	}

	.esg-info > .message-wrapper {
		margin-bottom: $small;
	}

	.price {
		margin-top: $extra-large;
		.card-wrapper.-list-item {
			padding-bottom: $small;
			padding-top: $small;

			.text-holder.-index-0 {
				font-weight: normal;
			}
		}
	}

	.fund-development-wrapper {
		position: relative;
		margin: 0 ($medium * -1) $large ($medium * -1);
		padding: $medium $medium $small $medium;

		&, &:before, &:after {
			background: #F2F0EE none no-repeat 0 0;
		}

		&:before, &:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			display: none;
		}

		&:before {
			left: 100%;
		}

		&:after {
			right: 100%;
		}

		.fund-development-list .fund-development {
			padding-bottom: $xtra-small;

			.value, .text {
				line-height: $progressRadialSize;
			}
		}
	}

	.fee-ongoing-charge-information {
		text-align: left;

		h3 {
			font-size: var(--font-size--regular);
		}
	}

	@media screen and (min-width: $desktop) {
		.price .card-wrapper.-list-item {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			margin-right: 0;
		}

		.fund-return-panel {
			.view-option-wrapper {
				width: 100%;

				fieldset, .sgw-button-toggle-options, legend {
					display: block;
					text-align: center;
				}
			}

			.chart-wrapper {
				margin: 0;
			}
		}

		.fund-development-wrapper {
			position: relative;
			margin-left: -80px;
			margin-right: -80px;
			padding: $large 0;

			&, h2, p {
				text-align: center !important;
			}

			&:before, &:after {
				display: block;
			}

			.fund-development-list {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex: 1;
				padding: 0;
				margin: 0;

				.fund-development {
					display: flex;
					flex: 1;
					justify-content: center;
					text-align: center;
				}

				.fund-development-item {
					display: block;
					padding-top: 0;
				}

				.value, .text {
					padding: 0 0 0 $xtra-small;
					line-height: inherit !important;
				}
			}
		}

		a.card-wrapper.-list-item.fund-fee-item {
			padding-right: 0;
		}
	}

	@media screen and (min-width: $desktop + 200) {
		.fund-return-panel .chart-wrapper {
			margin-left: -180px;
			margin-right: -180px;

			.axis-wrapper text {
				font-size: 12px;
				&.-empty {
					font-size: 0;
				}
			}

			.graph-wrapper path {
				stroke-width: 1.5;
			}
		}

		.fund-development-wrapper {
			margin-left: -180px;
			margin-right: -180px;
		}
	}

	@media screen and (min-width: $desktop + 300) {
		.fund-return-panel .chart-wrapper {
			margin-left: -200px;
			margin-right: -200px;
		}
	}

	@media screen and (max-width: 350px) {
		/*
		ul.fund-development-list {
			margin: 0 ($medium * -1) !important;
		}
		*/

		.fund-return-panel .view-option-wrapper button {
			margin-right: 5px;
		}
	}
}

.fund-development {
	position: relative;
	padding: 0;
	margin: 0 0 $xtra-small 0;

	.fund-development-item {
		padding: 0 $small 0 ($progressRadialSize + $xtra-small);
		position: relative;
		display: flex;
		flex-direction: row;
		min-height: $large;
	}

	.value {
		padding: 0 $xtra-small;
		font-size: var(--font-size--medium);
		text-align: left;
		min-width: 84px;
		font-weight: 700;
	}

	.text {
		text-align: left;
		font-size: var(--font-size--regular);
		width: 100%;
	}

	.development-status {
		@include progressRadial($progressRadialSize, $progressRadialView, #66BC79, #ECE5E0, #FFF);
		position: absolute;
		left: 0;
		top: 0;
		margin: 2px 0 0 0;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: transparent url('../../static/icon/arrow-down.svg') no-repeat center center / auto 40%;
			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 22 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3995 26.8864C10.7845 27.0379 11.2167 27.0379 11.6016 26.8864C11.7934 26.8099 11.9678 26.7004 12.1124 26.5623L21.5392 17.5617C22.1536 16.9767 22.1536 16.0271 21.5392 15.4406C20.9263 14.854 19.9316 14.854 19.3172 15.4406L12.5712 21.8805L12.5712 1.5001C12.5712 0.672046 11.8688 -4.25671e-07 10.9998 -4.62182e-07C10.1324 -4.98627e-07 9.4284 0.672046 9.4284 1.5001L9.4284 21.8805L2.68238 15.4406C2.06954 14.854 1.07484 14.854 0.460422 15.4406C0.153999 15.7331 1.17442e-06 16.1171 1.15695e-06 16.5011C1.13949e-06 16.8852 0.153999 17.2692 0.460422 17.5617L9.88882 26.5623C10.0334 26.7004 10.2078 26.8099 10.3995 26.8864Z' fill='%2300383D'/%3E%3C/svg%3E%0A");
			transform-origin: center;
		}

		&.-positive:after {
			transform: rotate(180deg);
		}

		&.-negative {
			@include progressRadial($progressRadialSize, $progressRadialView, #ECE5E0, #C5686A, #FFF);
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

.fund-risk-bullet-wrapper {
	position: relative;
	text-align: center;
	padding: $xtra-small 0 0 0;

	> span {
		display: inline-block;
		width: 10px;
		height: 10px;
		margin: 3px;
		border-radius: 100%;
		border: 1px solid var(--seagreen-dark);
		background: transparent none no-repeat 0 0;
		&.active {
			background-color: var(--seagreen-dark);
		}
	}
}
