@import "~@eika/core-style";

.card-expander-wrapper {
	position: relative;

	&.-expander {
		border: 2px solid var(--seagreen-pastel);
		border-radius: $medium;
		padding: $medium;
		&.-open {
			&, .card-wrapper {
				background-color: var(--seagreen-xtra-light);
			}

			.expander-btn {
				&:before {
					transform: rotate(0);
				}

				&:after {
					display: none;
				}
			}
		}

		.expander-btn {
			display: inline;

			&:before {
				content: '';
				display: inline-block;
				background-color: var(--seagreen-dark);
				border-radius: 100%;
				width: 36px;
				height: 36px;
				background: var(--seagreen-dark) url('../../static/icon/small-arrow-up-white.svg') no-repeat center center / auto 30%;
				background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4167 6.75011C13.4174 6.87075 13.3943 6.99034 13.3486 7.10202C13.303 7.21371 13.2358 7.31529 13.1508 7.40094C13.0656 7.48686 12.9642 7.55505 12.8525 7.60159C12.7408 7.64813 12.621 7.67209 12.5 7.67209C12.379 7.67209 12.2592 7.64813 12.1475 7.60159C12.0358 7.55505 11.9344 7.48686 11.8492 7.40094L7.00001 2.54261L2.15084 7.40094C1.97823 7.57355 1.74412 7.67052 1.50001 7.67052C1.2559 7.67052 1.02179 7.57355 0.849174 7.40094C0.676562 7.22833 0.57959 6.99422 0.57959 6.75011C0.57959 6.62924 0.603397 6.50955 0.649653 6.39788C0.695908 6.28621 0.763705 6.18474 0.849174 6.09927L6.34917 0.599273C6.43439 0.513356 6.53578 0.445161 6.64748 0.398623C6.75918 0.352085 6.879 0.328125 7.00001 0.328125C7.12102 0.328125 7.24083 0.352085 7.35254 0.398623C7.46424 0.445161 7.56563 0.513356 7.65084 0.599273L13.1508 6.09927C13.2358 6.18493 13.303 6.28651 13.3486 6.39819C13.3943 6.50988 13.4174 6.62947 13.4167 6.75011Z' fill='%23fff'/%3E%3C/svg%3E%0A");
				transform-origin: center;
				transform: rotate(180deg);
			}

			&:after {
				content: '';
				position: absolute;
				left: $medium * -1;
				right: $medium * -1;
				top: $medium * -1;
				bottom: $medium * -1;
			}
		}
	}

	&.-static .header-section h2 {
		font-weight: 400 !important;
		font-size: var(--font-size--larger) !important;
	}

	.header-section {
		position: relative;
		display: block;
		h2 {
			font-weight: 700 !important;
			font-size: var(--font-size--medium-large) !important;
		}

		.header-section-content {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			position: inherit;

			.-row {
				display: flex;
				gap: 4px;
				flex-wrap: wrap;
			}
		}
	}
}

/******************************************************************************
font scela
******************************************************************************/
.font-scale-large .carousel-wrapper {
}