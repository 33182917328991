@import "~@eika/core-style";
@import "../../styles/mixin";
@import "../../styles/variables";

.spareavtale-wrapper {
    position: relative;

    .card-wrapper {
        margin-bottom: $small;
    }

    .card-wrapper.-spareavtale-card {
        box-shadow: none !important;
        border: 0 none !important;
        background-color: #F0F8F9;
        padding-bottom: $large;
        padding-top: $large;

        .label-holder .-spareavtar-icon {
            width: 14px;
            height: 14px;
            background: transparent url('../../static/icon/schedule-blank.svg') no-repeat left center / auto 100%;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6C18.5304 6 19.0391 6.21071 19.4142 6.58579C19.7893 6.96086 20 7.46957 20 8V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H18ZM18 4H6C4.93913 4 3.92172 4.42143 3.17157 5.17157C2.42143 5.92172 2 6.93913 2 8V18C2 19.0609 2.42143 20.0783 3.17157 20.8284C3.92172 21.5786 4.93913 22 6 22H18C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18V8C22 6.93913 21.5786 5.92172 20.8284 5.17157C20.0783 4.42143 19.0609 4 18 4Z' fill='%2300383D'/%3E%3Cpath d='M20 5H4L3 9H21L20 5Z' fill='%2300383D'/%3E%3Cpath d='M8 7V3' stroke='%2300383D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 7V3' stroke='%2300383D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 7C7.73478 7 7.48043 6.89464 7.29289 6.70711C7.10536 6.51957 7 6.26522 7 6V4H9V6C9 6.26522 8.89464 6.51957 8.70711 6.70711C8.51957 6.89464 8.26522 7 8 7Z' fill='%2300383D'/%3E%3Cpath d='M16 7C15.7348 7 15.4804 6.89464 15.2929 6.70711C15.1054 6.51957 15 6.26522 15 6V4H17V6C17 6.26522 16.8946 6.51957 16.7071 6.70711C16.5196 6.89464 16.2652 7 16 7Z' fill='%2300383D'/%3E%3C/svg%3E%0A");
            margin-bottom: 0;
            > span {
                display: none;
            }
        }

        .text.-konto-type {
            margin-bottom: $small;
        }

        .text.-konto-type,
        .text.-label-text {
            opacity: .7;
        }

        .spareavtale-card-link-wrapper {
            text-align: center;
        }
    }

    .card-wrapper.-new-spareavtale-card {
        box-shadow: none;
        border: 2px solid #E6F3F5;
        padding-right: 50px;
        &:before {
            content: '';
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            background: transparent url('../../static/icon/plus-with-circle.svg') no-repeat center center / auto 100%;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z' fill='%234D7477'/%3E%3Cpath d='M18.5833 13.0833H14.9167V9.41667C14.9167 9.17355 14.8201 8.94039 14.6482 8.76849C14.4763 8.59658 14.2431 8.5 14 8.5C13.7569 8.5 13.5237 8.59658 13.3518 8.76849C13.1799 8.94039 13.0833 9.17355 13.0833 9.41667V13.0833H9.41667C9.17355 13.0833 8.94039 13.1799 8.76849 13.3518C8.59658 13.5237 8.5 13.7569 8.5 14C8.5 14.2431 8.59658 14.4763 8.76849 14.6482C8.94039 14.8201 9.17355 14.9167 9.41667 14.9167H13.0833V18.5833C13.0833 18.8264 13.1799 19.0596 13.3518 19.2315C13.5237 19.4034 13.7569 19.5 14 19.5C14.2431 19.5 14.4763 19.4034 14.6482 19.2315C14.8201 19.0596 14.9167 18.8264 14.9167 18.5833V14.9167H18.5833C18.8264 14.9167 19.0596 14.8201 19.2315 14.6482C19.4034 14.4763 19.5 14.2431 19.5 14C19.5 13.7569 19.4034 13.5237 19.2315 13.3518C19.0596 13.1799 18.8264 13.0833 18.5833 13.0833Z' fill='white'/%3E%3C/svg%3E");
        }
    }
}

/******************************************************************************
font scela
******************************************************************************/
.font-scale-large .ordre-wrapper {
}
