@import "~@eika/core-style";

$space: 12px;

.carousel-wrapper {
	position: relative;
	margin: 0 ($space * -1);

	ul.gallery {
		padding: $space;
		display: grid;
		grid-template-columns: repeat(100, 80vw);
		grid-template-rows: 1fr;
		grid-column-gap: $space;
		grid-row-gap: $space;
		margin: ($space * -1) 0;
		overflow: scroll;		
		scroll-snap-type: both mandatory;
		scroll-padding: $space;

		// Hide scrollbar in IE.
		-ms-overflow-style: none;
		&.active {
			scroll-snap-type: unset;
		}

		> li {
			scroll-snap-align: center;
			display: inline-block;

			> a {
				height: 100%;
			}
		}
	}

	// Hide scrollbar in WebKit and Blink powered Browsers.
	ul.gallery::-webkit-scrollbar {
		display: none;
	}

}

/******************************************************************************
font scela
******************************************************************************/
.font-scale-large .carousel-wrapper {
	/*
	.carousel-list > li.list-item {
		width: auto !important;
		display: block;
		margin-bottom: $small;
	}
	*/
}