@import "~@eika/core-style";
@import "../../styles/variables";

.sprouts-wrapper {
	position: relative;
	display: inline-block;

	.sprouts-item {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 40px;
		background: transparent url('../../static/icon/leave.svg') no-repeat center center / auto 70%;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PScwIDAgMjQgMjQnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTIzIDJDMjMgMS40NSAyMi41NiAxLjAxIDIyLjAxIDFDMjEuNDYgMSAyMS4wMiAxLjQ0IDIxLjAxIDEuOTlDMjEuMDEgMi40OSAyMC44MyA1IDE4LjAxIDVIMTIuMDFDNS45MyA1IDEgOS45MyAxIDE2VjIyQzEgMjIuNTUgMS40NSAyMyAyIDIzSDZDMTUuNjggMjMgMjAgMTguMzcgMjAgOFY2LjY2QzIyLjMyIDUuNzggMjMgMy4zOSAyMyAyWk0xOCA4QzE4IDE1LjggMTUuOTYgMjEgNiAyMUgzVjE2QzMgMTEuMDQgNy4wNCA3IDEyIDdIMTIuM0M5LjcxIDguODIgNy42NCAxMS43IDcuMDIgMTQuOEM2LjkxIDE1LjM0IDcuMjYgMTUuODcgNy44IDE1Ljk4QzcuODcgMTUuOTkgNy45MyAxNiA4IDE2QzguNDcgMTYgOC44OCAxNS42NyA4Ljk4IDE1LjJDOS45IDEwLjU4IDE0LjU5IDcgMTggN1Y4WicgZmlsbD0nIzMyQTU0QycvPjwvc3ZnPgo=");
		opacity: .4;
        &.-active{
        	opacity: 1;
        }
	}
}