@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";

@mixin amountFieldCenter() {
	font-size: var(--font-size--largest);
	font-weight: 700;
	text-align: center;
}

@mixin amountFieldUnit( $unit: 'kr', $space:20, $start: 26) {
    @for $i from 0 through 28 {
        &.-length-#{$i} {
            .amount-field-holder label:before {
              content: "\00a0 #{$unit}";
              margin-left: #{($i*$space) + $start}px;
            }
        }
    } 
}
 
@mixin _amountFieldUnit( $unit: 'kr') {
    @for $i from 0 through 28 {
        &.-length-#{$i} {
            .amount-field-holder label:before {
				$chr: '\00a0 \00a0';
				$content: '\00a0 \00a0';
				@for $j from 0 to $i {
					$content: $content + $chr;
				}
				content: "#{$content} #{$unit}";
            }
        }
    } 
}

@mixin amountStandardField() {
	text-align: left;
	@include amountFieldUnit( 'kr', 8, 14);

	&.-unit-percent {
		@include amountFieldUnit( '%', 8, 14 );
	}

	input {
		max-width: 320px;
	}

	.amount-field-holder {
		background-color: transparent;			
		padding: 0 0 $xtra-small 0;

		label {
			&, > span, &:before {
				font-weight: normal;
				font-size: var(--font-size--medium);
				font-weight: 600;
			}

			> span {
				margin-bottom: $xtra-small;
			}

			> span.sgw-input__input-sublabel {
				font-size: var(--font-size--regular);
				font-weight: normal;
				display: block;
			}

			&:before {
			    text-align: left !important;
				font-size: var(--font-size--medium);
			    left: $medium;
				margin-bottom: $small*.7;
				z-index: 4;
				white-space: nowrap;
			}
		}

		input.sgw-input__input {
		    text-align: left !important;
    		font-weight: normal;
			font-size: var(--font-size--medium);
    		border: 1px solid var(--seagreen-medium);
    		margin: $xtra-small 0 0 0;
    		background-color: var(--white);
		}	
	}


	.button-holder, .checkbox-holder {
		display: block;
	}

	.sgw-attention-message.type-error {
		margin: 0;
	}

	.sgw-label.error {
		margin: $xtra-small 0 0 0;				
	} 	
};


.amount-field-wrapper {
	position: relative;
	padding: 0;
	margin: 0 0 $small 0;
	text-align: center;
	@include amountFieldUnit();

	&.-unit-percent {
		@include amountFieldUnit( '%' );
	}

	.amount-field-holder {
		background-color: var(--seagreen-light);
		border-radius: $border-round;
		padding: $medium;

		.sgw-label.error.sgw-input__error-label {
			display: none;
		}

		label {
			position: relative;
			display: block;


			> span {
				position: relative;
				display: block;
				padding: 0;
				margin: 0;
				/* @include ariaVisible(); */
			}

			&:before {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				margin-bottom: -4px;
				@include amountFieldCenter();
			}

		}

		input.sgw-input__input {
			@include amountFieldCenter();
			width: 100%;
			border: 0 none;
		    opacity: 1;
		    margin: 0 0 0 ($medium * -1.5);
		    padding: 0 $medium;
		    padding-right: $medium;
			background: transparent none no-repeat 0 0;
		    text-align: center !important;
		    position: relative;
		    z-index: 2;
		}
	}

	.sgw-attention-message.type-error {
		text-align: left;
		margin: $xtra-small auto 0 auto;
		display: table;
		padding-top: $xtra-small;
		padding-bottom: $xtra-small;

		.message-text{
			color: var(--red);
		}

		+ .message-wrapper.-attention {
			display: none;
		}
	}

	.message-wrapper.-attention {
		padding: 0;
		text-align: left;
		margin-top: $xtra-small;
	}

	.button-holder, .checkbox-holder {
		display: block;
		padding: $small 0 0 0;
		&.-error {
			margin: ($small * -1) 0 0 0;
			padding: $small;
			background-color: var(--red-pastel);
			border-radius: 0 0 $xtra-small $xtra-small;
		}
	}

	.button-holder {
		padding: 0;
		text-align: left;

		&.-error {
			padding-top: 0;
		}

		button {
			margin: 0 $small $xtra-small 0;
		}
	}

	&.-standard {
		@include amountStandardField();
	}

   	@media screen and (min-width: $maxWidthSmall) {
		@include amountStandardField();

		.amount-field-holder {
			label {
				> span {
					@include normalVisible();
				}
			}
		}
    }
}
