@import "~@eika/layout-style";
@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";

@mixin gridEnd( $delta: 0 ) {
    @for $i from 0 through 12 {
        &.-end-#{$i} {
            grid-column-end: $i - $delta;
        }
    } 
}

@mixin gridStart( $delta: 0 ) {
    @for $i from 0 through 12 {
        &.-start-#{$i} {
            grid-column-start: $i - $delta;
        }
    } 
}

.sparing-react-app.-is-on-webview .grid-layout-wrapper.header-info {
    @media screen and (max-width: $desktop) {
        .sgw-layout-item > .sgw-content {
            margin-top: -20px;
        }
    }
}

.sparing-react-app.-is-on-webview .grid-layout-wrapper.sparing-header {
    @media screen and (max-width: $desktop) {
        .sgw-content {
            padding-top: $medium;
        }
    }
}

.grid-layout-wrapper {
    position: relative;

    &.sparing-content, &.sparing-header,  &.sparing-top {
        &, > .sgw-layout-item {
            background-color: transparent;
        }
    }

    &.sparing-header {
        position: relative;
        z-index: 1;
        .sgw-content {
            padding-bottom: 0;
        }
    }

    &.sparing-content {
        position: relative;
    }

    &.header-info {
        background-color: var(--theme-medium);

        .sgw-layout-item > .sgw-content {
            padding-top: 0;
            padding-bottom: 0;            
            background-color: var(--theme-medium);
            margin-top: 0;
        }

        h1 {
            margin: 0;
            padding: $large 0 $medium 0;
            font-size: var(--font-size--largest);

            + .header-total-value, + div > .header-total-value {
                padding-top: $small;
            }
        }

        .header-total-value {
            font-size: var(--font-size--largest);
            font-weight: 700;
        }

        .action-button-list-wrapper {
            margin-top: $large;
            .action-button-list {
                margin-top: $large * -1.5;
            }
        }
    }

    + .grid-layout-wrapper {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    &.sparing-content,
    &.sparing-header,
    &.sparing-top {
        padding-top: $medium !important;
    }

    &.sparing-content {
        padding-top: $large !important;
    }


    &.sgw-layout.-count-1 > .sgw-layout-item.six {
        grid-column-start: 4;
        grid-column-end: 10;
    }

    &.sgw-layout.-count-1 > .sgw-layout-item.eight {
        grid-column-start: 3;
        grid-column-end: 11;
    }

    &.sgw-layout > .sgw-layout-item.twelve {
        grid-column: span 12;
    }

    &.-count-1.sgw-layout > .sgw-layout-item {
        @include gridEnd();
        @include gridStart();
    }

    &.six-four-container {
        .sgw-layout-item.six {
            grid-column-start: 2;
            grid-column-end: 7;
        }
        .sgw-layout-item.four {
            grid-column-start: 8;
            grid-column-end: 12;
        }
    }

    &.sgw-layout.-count-1.-static {
        display: block;

        > .sgw-layout-item {
            margin: 0 auto;
        }

        > .sgw-layout-item.eight {
            width: $gridWidth * 8;
        }

        > .sgw-layout-item.six {
            width: $gridWidth * 6;
        }
    }

    @media screen and (max-width: $maxDesktop) {
        &.sgw-layout.-count-1 > .sgw-layout-item.six {
            grid-column-start: 2;
            grid-column-end: 8;
            @include gridEnd(3);
        }

        &.sgw-layout.-count-1 > .sgw-layout-item.eight {
            grid-column-start: 2;
            grid-column-end: 8;
            @include gridEnd(2);
        }

        &.six-four-container {
            .sgw-layout-item.six {
                grid-column-start: 1;
                grid-column-end: 5;
                @include gridEnd(3);

            }
            .sgw-layout-item.four {
                grid-column-start: 6;
                grid-column-end: 12;
                @include gridEnd(1);

            }
        }
 
        &.sgw-layout > .sgw-layout-item.twelve {
            grid-column-start: 1 !important;
            grid-column-end: 12 !important;
        }
    }

    @media screen and (max-width: $desktop) {
        &.sparing-top {
            display: none !important;
        }

        &.sparing-header {
            padding-top: 0 !important;
            .sgw-content:before {
                content: '';
                height: $small;
                display: block;
            }
        }

        &.six-container.-count-1 .sgw-layout-item {
            min-width:0;
            display: block;
        }

        &.sgw-layout > .sgw-layout-item {
            grid-column-start: start !important;
            grid-column-end: end !important;
        }

        &.header-info {
            text-align: center;

            .sgw-layout-item > .sgw-content {
                padding: 0;
            }

            h1 {
                padding: $medium 0;
                font-weight: 700;
                font-size: var(--font-size-eika--small);
            }

            .header-total-value {
                font-size: var(--font-size--largest);
                margin: ($large * -1) 0 $medium 0;
            }

            .action-button-list-wrapper {
                margin-top: $large;
                .action-button-list {
                    margin-top: $large * -1.5;
                }
            }
        }
 
        .sgw-layout-item.only-for-desktop {
            display: none !important;
        }
    }

    @media screen and (max-width: ($gridWidth * 8) ) {
        &.sgw-layout.-count-1.-static .sgw-layout-item.eight {
            width: auto;
        }
    }

    @media screen and (max-width: ($gridWidth * 6)) {
        &.sgw-layout.-count-1.-static .sgw-layout-item.six {
            width: auto;
        }
    }
}
