@import "~@eika/layout-style";
@import "~@eika/core-style";
@import "./animation";
@import "./mixin";
@import "./variables";

html {
    position: 'relative';
}

body {
    overflow-x: hidden;
}

.content-list-wrapper {
    list-style: none;
    margin: 0;
    padding: 0;

    &.-extra-small > li {
        padding-bottom: $xtra-small;
    }

    > li {
        display: block;
        padding: 0 0 $small 0;
    }

    &.-small-gap  > li {
        padding-bottom: $xtra-small;            
    }

    &.-no-gap  > li {
        padding-bottom: 0;
    }

    &.-dashed-list > li,
    &.-checked-list > li {
        padding-left: $medium;
        position: relative;
        &:before {
            content: '-';
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.content-max-width {
    margin: 0 auto;
    display: block;
    &, .sgw-market-card {
        max-width: $maxWidthSmall;
    }
}

.content-max-width-medium {
    margin: 0 auto;
    display: block;
    &, .sgw-market-card {
        max-width: $maxWidthMedium;
    }    
}

.content-max-width-large {
    margin: 0 auto;
    display: block;
    &, .sgw-market-card {
        max-width: $maxWidthLarge;
    }
}

.aria-visible {
    @include ariaVisible();
}

.left-appending {
    width: 100%;
    @include animation( left-appending, .2s, ease, 0s );
}

.right-appending {
    width: 100%;
    @include animation( right-appending, .2s, ease, 0s );
}

.top-appending {
    height: 98%;
    @include animation( top-appending, .2s, ease, 0s );
}

.shake {
    @include animation( shake, 2s, ease, .6s );
    @include animation-repeat();
}

.center {
    text-align: center;
}

.text-left {
    text-align: left;
}

ul.flat {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.table-wrapper {
    display: table;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    &.-split-50-50 > li {
        width: 49%;
        padding: 0 0 0 1%;
        &:first-child {
            padding: 0 1% 0 0;            
        }
    }

    > li {
        display: table-cell;
        vertical-align: top;
    }
}

.tag {
    display: inline-block;
    font-weight: normal;
    border-radius: 6px;

    &.-semibold {
        font-weight: 600;
    }

    &.-bold {
        font-weight: 700;
    }

    &.-error {
        /*background-color: var(--red-pastel); */
        color: var(--red);
    }
}

.total-value {
    font-size: 52px;
    line-height: 40px;
    font-weight: 700;

    > span {
        display: block;

        &.total-value-label{
            font-size: var(--font-size--regular);
            font-weight: 400;
        }
    }

    .message-text {
        font-size: var(--font-size--regular);
        font-weight: normal;
    }
}

.font-normal { 
    font-weight: normal;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.wallpaper-wrapper {
    position: relative;
    padding: $medium 0 0 0;
    min-height: $large * 2;

    &.-info:before {
        background-color: var(--seagreen-light);
    }

    &:before {
        content: "";
        position: absolute;
        left: $large * -1;
        right: $large * -1;
        top: 0;
        bottom: 15%;
        min-height: 140px;
        background: var(--theme-light) none no-repeat 0 0;
        border-radius: $small;
        z-index: 0;
    }

    > * {
        position: relative;
        z-index: 1;
    }
}

.max-width-300 {
    max-width: 300px;
    &.-center {
         margin-left: auto;
         margin-right: auto;
    }
}

.max-width-400 {
    max-width: 400px;
    &.-center {
         margin-left: auto;
         margin-right: auto;
    }
}


.max-width-500 {
    max-width: 500px;
    &.-center {
         margin-left: auto;
         margin-right: auto;
    }
}

.max-width-600 {
    max-width: 600px;
    &.-center {
         margin-left: auto;
         margin-right: auto;
    }
}

.max-width-700 {
    max-width: 700px;
    &.-center {
         margin-left: auto;
         margin-right: auto;
    }
}


.paragraph-extra-small {
    padding-bottom: $xtra-small;
    &.-include-top {
        padding-top: $xtra-small;            
    }
    &.-only-top {
        padding-top: $xtra-small;
        padding-bottom: 0;
    }
}

.paragraph-small {
    padding-bottom: $small;
    &.-include-top {
        padding-top: $small;            
    }
    &.-only-top {
        padding-top: $small;
        padding-bottom: 0;
    }
}

.paragraph {
    padding-bottom: $medium;
    &.-include-top {
        padding-top: $medium;            
    }
    &.-only-top {
        padding-top: $medium;
        padding-bottom: 0;
    }
}

.paragraph-large {
    padding-bottom: $medium * 2;
    &.-include-top {
        padding-top: $medium * 2;            
    }
    &.-only-top {
        padding-top: $medium * 2;
        padding-bottom: 0;
    }
}

.paragraph-largest {
    padding-bottom: ($medium * 2) + $small;
    &.-include-top {
        padding-top: ($medium * 2) + $small;  
    }
    &.-only-top {
        padding-top: ($medium * 2) + $small;  
        padding-bottom: 0;
    }
}

.paragraph-small {
    padding-bottom: $small;
    &.-include-top {
        padding-top: $small;            
    }
    &.-only-top {
        padding-top: $small;
        padding-bottom: 0;
    }
}

.-no-wrap, .no-wrap {
    white-space: nowrap;
}

.hidden {
    visibility: hidden;
}

.-opacity, .opacity {
    @include opacity(1);
    &.-level-9 { @include opacity(.9); }
    &.-level-8 { @include opacity(.8); }
    &.-level-7 { @include opacity(.7); }
    &.-level-6 { @include opacity(.6); }
    &.-level-5 { @include opacity(.5); }
    &.-level-4 { @include opacity(.4); }
    &.-level-3 { @include opacity(.3); }
    &.-level-2 { @include opacity(.2); }
}

a.link-tag {
    font-weight: 600;
    border-bottom: 2px solid #97c5ca;

}

/* Det må ha sparing-react-app wrapper for å styre font-scale */
.sparing-react-app {
    .-only-for-mobile, .-only-for-desktop, .-only-for-test,
    .only-for-mobile, .only-for-desktop, .only-for-test {
        display: none;
    }

    @media screen and (min-width: $desktop) {
        .-only-for-desktop, .-only-for-test,
        .only-for-desktop, .only-for-test {
            display: block;
        }

        .desktop-paragraph-large {
            padding-bottom: $medium * 2;
            &.-include-top {
                padding-top: $medium * 2;            
            }
            &.-only-top {
                padding-top: $medium * 2;
                padding-bottom: 0;
            }
        }

    }

    @media screen and (max-width: $desktop) {
        .-only-for-mobile, .only-for-mobile {
            display: block;
        }

        .center-only-for-mobile {
            text-align: center;
        }
    }

    @media screen and (min-width: $maxWidthSmall) {
        .-only-for-desktop, .-only-for-test,
        .only-for-desktop, .only-for-test {
            &.-form {
                display: block;
            }
        } 

        .-only-for-mobile, .only-for-mobile {
            &.-form {
                display: none;
            }
        }
    }

    @media screen and (max-width: $maxWidthSmall) {
        .-only-for-mobile, .only-for-mobile {
            &.-form {
                display: block;
            }
        }

        .-only-for-desktop, .-only-for-test,
        .only-for-desktop, .only-for-test {
            &.-form {
                display: none;
            }
        }
    }
}