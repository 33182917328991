@charset "UTF-8";
@import "./variables";

@-webkit-keyframes opacity-reveal {
  0%   { opacity: 0;}
  100% { opacity: 1;}
}

@keyframes opacity-reveal {
  0%   { opacity: 0;}
  100% { opacity: 1;}
}

@-webkit-keyframes opacity-hidden {
  0%   { opacity: 1;}
  100% { opacity: 0;}
}

@keyframes opacity-hidden {
  0%   { opacity: 1;}
  100% { opacity: 0;}
}

/*****************************************************************************/
@-webkit-keyframes left-appending{
  0%   { margin-left: 100%; opacity: 0; }
  100% { margin-left: 0; opacity: 1; }
}

@keyframes left-appending{
  0%   { margin-left: 100%; opacity: 0; }
  100% { margin-left: 0; opacity: 1; }
}

/*****************************************************************************/
@-webkit-keyframes right-appending{
  0%   { margin-left: -100%; opacity: 0; }
  100% { margin-left: 0; opacity: 1; }
}

@keyframes right-appending{
  0%   { margin-left: -100%; opacity: 0; }
  100% { margin-left: 0; opacity: 1; }
}

/*****************************************************************************/
@-webkit-keyframes top-appending{
  0%   { margin-top: 20%; opacity: 0; }
  100% { margin-top: 0; opacity: 1; }
}

@keyframes top-appending{
  0%   { margin-top: 20%; opacity: 0; }
  100% { margin-top: 0; opacity: 1; }
}

/*****************************************************************************/
@-webkit-keyframes bottom-appending{
  0%   { margin-top: -20%; opacity: 0; }
  100% { margin-top: 0; opacity: 1; }
}

@keyframes bottom-appending{
  0%   { margin-top: -20%; opacity: 0; }
  100% { margin-top: 0; opacity: 1; }
}

/*****************************************************************************/
@-webkit-keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  1% { transform: translate(-1px, -2px) rotate(-1deg); }
  2% { transform: translate(-3px, 0px) rotate(1deg); }
  3% { transform: translate(3px, 2px) rotate(0deg); }
  4% { transform: translate(1px, -1px) rotate(1deg); }
  5% { transform: translate(-1px, 2px) rotate(-1deg); }
  6% { transform: translate(-3px, 1px) rotate(0deg); }
  7% { transform: translate(3px, 1px) rotate(-1deg); }
  8% { transform: translate(-1px, -1px) rotate(1deg); }
  9% { transform: translate(1px, 2px) rotate(0deg); }
  10% { transform: translate(1px, -2px) rotate(-1deg); }
  11% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  1% { transform: translate(-1px, -2px) rotate(-1deg); }
  2% { transform: translate(-3px, 0px) rotate(1deg); }
  3% { transform: translate(3px, 2px) rotate(0deg); }
  4% { transform: translate(1px, -1px) rotate(1deg); }
  5% { transform: translate(-1px, 2px) rotate(-1deg); }
  6% { transform: translate(-3px, 1px) rotate(0deg); }
  7% { transform: translate(3px, 1px) rotate(-1deg); }
  8% { transform: translate(-1px, -1px) rotate(1deg); }
  9% { transform: translate(1px, 2px) rotate(0deg); }
  10% { transform: translate(1px, -2px) rotate(-1deg); }
  11% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}
