@use "sass:math";

@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";

/** ************************************************************************
 ************************************************************************* */
.action-button-list-wrapper {
    position: relative;

    &.flex-center .action-button-list {
        justify-content: center;
    }

    &.flex-nowrap .action-button-list {
        flex-wrap: nowrap;
    }

    &.cell-3,  &.cell-2 {
        .action-button-list  {
            width: 106%;
            margin-left: -3%;

            > li {
                padding: 0 0 0 0;
                margin: 0 3% 4% 3%;
                width: 44%;
            }
        }
    }

    &.cell-3 .action-button-list > li {
        width: 27%;
    }

    &.wallpaper {
        button {
            position: static;
            grid-item--min-width: 0 !important;
            min-width: 0;
            min-height: 128px !important;

            &:not(.-disabled):before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 30%;
                bottom: 0;
                background: var(--theme-medium) none no-repeat 0 0;
                border-radius: 6px;
            }

            &.-disabled {
                pointer-events: none;
            }

            > span {
                position: relative;
                z-index: 1;
            }
        }
    }

    &.collection-small {
        border-radius:  12px;
        background: var(--theme-light) none no-repeat 0 0;
        padding: $medium $small;        
        .action-button-list {
            justify-content: flex-start;
            > li {
                padding-bottom: $small;
            }
        }

        button {

            &:hover .sgw-icon {
                color: var(--seagreen-dark);
            }

            span {
                white-space: normal !important;
            }
            .sgw-icon {
                width: 48px;
                height: 48px;
                background: var(--theme-medium) none no-repeat 0 0;
                color: var(--seagreen-dark);


                .icon {
                    width: 28px !important;
                    height: 28px !important;
                    margin: -5px 0 0 -5px;
                }
            }
        }
    }

    &.-expandable {
        margin-bottom: $large + $small;
        padding-bottom: $large + $small;

        &.collection-small {
            margin-bottom: $large;
            padding-bottom: $small;
            .action-button-list {
                max-height: 120px;
            }
        }

        &.show-double {
            .action-button-list {
                max-height: 270px;
            }

            .collection-small .action-button-list {
                max-height: 240px;              
            }
        }

        &.-expanded {
            .action-button-list {
                max-height: 600px;
            }

            .expander-btn:after {
                background-position: center top;
                transform: rotate(0);
            }
        }

        .action-button-list {
            @include transition( all, .2s, ease-in-out);
            max-height: 134px;
            overflow: hidden;
        }

        .expander-btn-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin-top: math.div($large, 2) * -1;
            text-align: center;
        }

        .expander-btn {
            display: inline-block;
            position: relative;
            width: $large;
            height: $large; 
            border-radius: 100%;
            background: var(--theme-light) none no-repeat center center / auto 90%;

            &:before {
                content: attr(data-text);
                position: absolute;
                left: 50%;
                bottom: 100%;
                width: 200px;
                margin-left: -100px;
                margin-bottom: math.div($large, 3) * -1;
                text-transform: uppercase;
                font-weight: normal;
                font-size: var(--font-size--smallest);
                text-align: center;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                bottom: 0;
                background: transparent url('../../static/icon/small-arrow-up.svg') no-repeat center bottom / auto 50%;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 8.00006C15.0007 8.13167 14.9755 8.26213 14.9258 8.38397C14.876 8.5058 14.8027 8.61662 14.71 8.71006C14.617 8.80379 14.5064 8.87818 14.3846 8.92895C14.2627 8.97972 14.132 9.00586 14 9.00586C13.868 9.00586 13.7373 8.97972 13.6154 8.92895C13.4935 8.87818 13.3829 8.80379 13.29 8.71006L7.99998 3.41006L2.70998 8.71006C2.52168 8.89837 2.26628 9.00415 1.99998 9.00415C1.73368 9.00415 1.47829 8.89837 1.28998 8.71006C1.10168 8.52176 0.995892 8.26636 0.995891 8.00006C0.995891 7.73376 1.10168 7.47837 1.28998 7.29006L7.28998 1.29006C7.38295 1.19633 7.49355 1.12194 7.61541 1.07117C7.73726 1.0204 7.86797 0.994263 7.99998 0.994263C8.13199 0.994263 8.2627 1.0204 8.38456 1.07117C8.50642 1.12194 8.61702 1.19633 8.70998 1.29006L14.71 7.29006C14.8027 7.3835 14.876 7.49432 14.9258 7.61615C14.9755 7.73799 15.0007 7.86845 15 8.00006Z' fill='%234D7477'/%3E%3C/svg%3E%0A");
                transform-origin: center;
                transform: rotate(180deg);
            }
        }
    }

    .action-button-list {
        z-index: 10;
        list-style: none;
        margin: 0 0 ($small * -1) 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        > li {
            position: relative;
            padding: 0 ($medium * 1.5);
            text-align: center;
            margin-bottom: $medium;
            display: flex;
            flex-direction: row;
            justify-content: center;


            &.tool-item.-MONTHLY-kjop-fond,
            &.tool-item.-monthly-kjop-fond {
                white-space: nowrap;
            }
        }
    }

    button {
        /* Midlertidig fiks på en bug med bank Bien 1720 */
        &.sgw-button.button--action:hover .sgw-icon {
            color: var(--seagreen-dark);
        }

        .sgw-icon {
            display: inline-block !important;
        }

        .block {
            display: block;
            word-break: keep-all;
        }
    }

    @media screen and (max-width: 350px) {
        &.collection-small.cell-3 .action-button-list { 
            justify-content: flex-start;
            > li {
                width: 44%;
                padding-bottom: $small + 4;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        &.-expandable .expander-btn {
            background-color: transparent;
        }
    }
}

.font-scale-large .action-button-list-wrapper {
    margin-bottom: 0;
    padding-bottom: $small;

    &, .action-button-list, .action-button-list li {
        display: block;
        text-align: left;
    }

    .action-button-list li > button {
        width: 100%;
        text-align: left;    
    }
}