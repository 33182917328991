@import "~@eika/core-style";
@import "../../../styles/mixin";
@import "../../../styles/variables";

@-webkit-keyframes konti-detail-titl-scale {
    0%   { transform: scale(1);   }
    50%  { transform: scale(1.4); }
    100% { transform: scale(1)    }
}

@keyframes konti-detail-titl-scale {
    0%   { transform: scale(1);   }
    50%  { transform: scale(1.4); }
    100% { transform: scale(1)    }
}

.konti-wrapper {
    position: relative;


    .vps-summary-section {
        margin-bottom: $medium; 
        .summary-header-wrapper > li {
            display: none;
            &:first-child {
                display: block;
            }
        }
    }

    #konti-detail-title {
        position: relative;

        &.scale {
            > span {
                 visibility: hidden;
            }

            &:before {
                visibility: visible;
                @include animation( konti-detail-titl-scale, 1.2s, ease, .2s );
                @include animation-repeat();
            }
        }

        &:before {
            content: attr(data-value);
            position: absolute;
            left: 0;
            top: 0;
            visibility: hidden;
            transform: scale(1);
        }
    }

    @media screen and (max-width: $desktop) {
        .vps-summary-section {
            margin-bottom: $small * -1;
        }
    }   
}

/******************************************************************************
font scela
******************************************************************************/
.font-scale-large .konti-wrapper {
}
