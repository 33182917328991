@use "sass:math";

@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";

/** ************************************************************************
 ************************************************************************* */
#sparing-react-app .other-service-button-list-wrapper,
.other-service-button-list-wrapper {
	position: relative;
	padding: 0 0 $medium 0;

	h2 {
		font-weight: normal;
		font-size: var(--font-size--medium);
		padding-bottom: $small;
	}

	.other-service-button-list > li .card-wrapper {
		margin: 0 0 $small 0;
		&.-list-item {
			margin-bottom: -1px;
		}

		&.-advicer {
			margin-bottom: $large;
		}
	}
}

.font-scale-large .other-service-button-list-wrapper {

}