@import "~@eika/core-style";
@import "../../styles/variables";

.profil-wrapper {
    position: relative;

    article {
    	margin: 0 0 $medium*2 0;

	    .card-wrapper .flex-text-holder .text:first-child {
	    	font-weight: normal;
	    }
    }

    .kunde-info-wrapper {
        margin-left: $medium * -1;
        margin-right: $medium * -1;
    }

    @media screen and (max-width: $desktop) {
    }

    @media screen and (min-width: $desktop) {
    }
}
