@use "sass:math";

@import "~@eika/core-style";
@import "../styles/animation";
@import "../styles/mixin";
@import "../styles/variables";

body.new-sgw-content .sgw-modal.sgw-content {
    padding-top: $medium !important;
    padding-bottom: $medium !important;


    @media screen and (max-width: $desktop) {
        border-top-left-radius: $medium !important;
        border-top-right-radius: $medium !important;
    }

    @media screen and (min-width: $desktop) {
        .header {
            width: calc(100% + $medium);
        }
    }
}

.sparing-react-app,
#sparing-react-app {
    margin-bottom: $large * 2;
    /*
    * {
        box-sizing: border-box;
    }
    */

    .fond-handel-automatic-increase-saving-wrapper,
    .card-wrapper, .flex-text-holder {
        &, * {
            box-sizing: border-box;
        }
    }
    &:after {
    }

    &:before {
        content: '';
        @include ariaVisible();
        width: $desktop;
        display: none;
    }

    &.-is-on-test {
    }

    &.-is-on-webview {
    }

    &.-is-on-mobile {
    }

    &.-app-spinner-start-up {
        padding-top: $large;
    }

    &.-is-eika-domain {
        .sparing-sammendrag-wrapper .grid-layout-wrapper.sparing-top {
            display: none;
        }
    }

    /* 
        @eika/layout-style@6.7.4 har ikke denne styling på .sgw-layout-item.
        den nye @eika/layout-style@6.8.1 i TEST miljø, har styling på margin,
        og brekker designet. 
    */
    .sgw-layout-item {
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: $small;
    }

    .header-total-value {
        font-size: var(--font-size--largest);
        font-weight: 700;
        text-align: center;
        margin: 0 0 $medium 0;
    }

    .header-total-text {
        font-weight: 600;
        font-size: var(--font-size--small);
        text-align: center;
        margin-top: $medium * -1;
    }

    .app-spinner-holder {
        padding: $large 0;
    }
    
    .value-swing, .-value-swing {
        &:before {
            content: '';
            display: inline-block;
            width: $small;
            height: $small;
            background: transparent none no-repeat center bottom / auto 80%;
            transform-origin: center;
            margin-right: 4px;
        }
        
        &.-none:before {
            display: none;
        }

        &.-size-large {
            font-size: var(--font-size--large);
            &:before {
                width: var(--font-size--large);
                height: var(--font-size--large);
                background-size: auto 75%;
            }
        }
    }

    .value-swing.-negative, .value-swing.-positive,
    .-value-swing.-negative, .-value-swing.-positive {
        &:before {
            background-image: url('../static/icon/swing-arrow.svg');
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.38212 0.0757561C7.13712 -0.0252514 6.86213 -0.0252514 6.61713 0.0757561C6.49513 0.12676 6.38414 0.199764 6.29214 0.291771L0.293245 6.29219C-0.0977483 6.68221 -0.0977483 7.31526 0.293245 7.70629C0.683238 8.09731 1.31623 8.09731 1.70722 7.70629L6.00014 3.41299L6.00014 16.9999C6.00014 17.552 6.44713 18 7.00012 18C7.55211 18 8.00011 17.552 8.00011 16.9999L8.00011 3.41299L12.293 7.70629C12.683 8.09731 13.316 8.09731 13.707 7.70629C13.902 7.51127 14 7.25525 14 6.99924C14 6.74322 13.902 6.4872 13.707 6.29219L7.70711 0.291771C7.61511 0.199764 7.50412 0.12676 7.38212 0.0757561Z' fill='%2300383D'/%3E%3C/svg%3E%0A");
        }
    }

    .value-swing.-negative:before,
    .-value-swing.-negative:before {
        background-position: center top;
        transform: rotate(180deg);
    }

    .info-text-link, .-info-text-link {
        white-space: nowrap;
        &:after {
            content: '';
            display: inline-block;
            width: var(--font-size--regular);
            height: var(--font-size--regular);
            margin: 0 0 -4px 4px;
            background: transparent none no-repeat center center / auto 100%;
            background-image: url('../static/icon/info.svg');
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0002 1.83301C9.18717 1.83301 7.41489 2.37062 5.90744 3.37787C4.39999 4.38512 3.22507 5.81675 2.53127 7.49174C1.83747 9.16673 1.65594 11.0098 2.00964 12.788C2.36333 14.5662 3.23637 16.1995 4.51835 17.4815C5.80034 18.7635 7.43368 19.6365 9.21184 19.9902C10.99 20.3439 12.8331 20.1624 14.5081 19.4686C16.1831 18.7748 17.6147 17.5999 18.622 16.0924C19.6292 14.585 20.1668 12.8127 20.1668 10.9997C20.1668 9.79589 19.9297 8.60389 19.4691 7.49174C19.0084 6.37959 18.3332 5.36907 17.482 4.51786C16.6308 3.66666 15.6202 2.99145 14.5081 2.53078C13.3959 2.07011 12.204 1.83301 11.0002 1.83301ZM11.0002 18.333C9.54977 18.333 8.13195 17.9029 6.92598 17.0971C5.72002 16.2913 4.78009 15.146 4.22505 13.806C3.67001 12.466 3.52478 10.9915 3.80774 9.56901C4.0907 8.14648 4.78913 6.83981 5.81472 5.81422C6.8403 4.78864 8.14698 4.09021 9.5695 3.80725C10.992 3.52429 12.4665 3.66952 13.8065 4.22456C15.1465 4.7796 16.2918 5.71953 17.0976 6.92549C17.9034 8.13145 18.3335 9.54928 18.3335 10.9997C18.3335 12.9446 17.5609 14.8099 16.1856 16.1851C14.8103 17.5604 12.9451 18.333 11.0002 18.333Z' fill='%234D7477'/%3E%3Cpath d='M11.0002 10.3945C10.757 10.3945 10.5239 10.4911 10.352 10.663C10.1801 10.8349 10.0835 11.0681 10.0835 11.3112V14.6662C10.0835 14.9093 10.1801 15.1425 10.352 15.3144C10.5239 15.4863 10.757 15.5829 11.0002 15.5829C11.2433 15.5829 11.4764 15.4863 11.6483 15.3144C11.8203 15.1425 11.9168 14.9093 11.9168 14.6662V11.3112C11.9168 11.0681 11.8203 10.8349 11.6483 10.663C11.4764 10.4911 11.2433 10.3945 11.0002 10.3945Z' fill='%234D7477'/%3E%3Cpath d='M11.0002 9.16634C11.5064 9.16634 11.9168 8.75594 11.9168 8.24967C11.9168 7.74341 11.5064 7.33301 11.0002 7.33301C10.4939 7.33301 10.0835 7.74341 10.0835 8.24967C10.0835 8.75594 10.4939 9.16634 11.0002 9.16634Z' fill='%234D7477'/%3E%3C/svg%3E%0A");
        }        
    }

    .kjop-fond-modal-wrapper .sgw-modal {
        .header {
            z-index: 4;
            &:after {
                display: none;
            }
        }

        .content {
            margin-top: $large * -1;
            padding-top: $xtra-small;
        }
    }

    input, select {
        &:focus, &:active, &:hover {
            outline: none !important;
        }
    }

    /* Midlertidig hack, siden mobilbank ikke støtter høyre ikon */
    .sgw-button.icon-right {
        position: relative;
        .sgw-link__text.sgw-link__text--icon-visible {
            padding-right: 30px;
            padding-left: 0;
        }

        .sgw-icon {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .h1, h1 {
        padding: 0;
        margin: 0;
        font-weight: $font-weight-bold;
        font-size: var(--font-size-eika--large);
        font-size: 22px;

        &.paragraph-extra-small { padding-bottom: $xtra-small; }
        &.paragraph-small { padding-bottom: $small; }
        &.paragraph { padding-bottom: $medium; }
        &.paragraph-large { padding-bottom: $large; }

        &.paragraph-extra-small.-include-top { padding-top: $xtra-small; }
        &.paragraph-small.-include-top { padding-top: $small; }
        &.paragraph.-include-top { padding-top: $medium; }
        &.paragraph-large.-include-top { padding-top: $large; }

        &.paragraph-extra-small.-only-top { padding-top: $xtra-small; padding-bottom: 0; }
        &.paragraph-small.-only-top { padding-top: $small; padding-bottom: 0; }
        &.paragraph.-only-top { padding-top: $medium; padding-bottom: 0; }
        &.paragraph-large.-only-top { padding-top: $large; padding-bottom: 0; }
    }

    .h2, h2 {
        padding: 0;
        margin: 0;
        font-weight: $font-weight-semibold;
        font-weight: $font-weight-normal;
        font-size: var(--font-size-eika--small);
        font-size: 22px;

        &.paragraph-extra-small { padding-bottom: $xtra-small; }
        &.paragraph-small { padding-bottom: $small; }
        &.paragraph { padding-bottom: $medium; }
        &.paragraph-large { padding-bottom: $large; }

        &.paragraph-extra-small.-include-top { padding-top: $xtra-small; }
        &.paragraph-small.-include-top { padding-top: $small; }
        &.paragraph.-include-top { padding-top: $medium; }
        &.paragraph-large.-include-top { padding-top: $large; }

        &.paragraph-extra-small.-only-top { padding-top: $xtra-small; padding-bottom: 0; }
        &.paragraph-small.-only-top { padding-top: $small; padding-bottom: 0; }
        &.paragraph.-only-top { padding-top: $medium; padding-bottom: 0; }
        &.paragraph-large.-only-top { padding-top: $large; padding-bottom: 0; }

    }

    /*.h3, h3 {*/
    .h3 {
        padding: 0;
        margin: 0;
        font-weight: $font-weight-normal;
        font-size: var(--font-size--regular);

        &.paragraph-extra-small { padding-bottom: $xtra-small; }
        &.paragraph-small { padding-bottom: $small; }
        &.paragraph { padding-bottom: $medium; }
        &.paragraph-large { padding-bottom: $large; }

        &.paragraph-extra-small.-include-top { padding-top: $xtra-small; }
        &.paragraph-small.-include-top { padding-top: $small; }
        &.paragraph.-include-top { padding-top: $medium; }
        &.paragraph-large.-include-top { padding-top: $large; }

        &.paragraph-extra-small.-only-top { padding-top: $xtra-small; padding-bottom: 0; }
        &.paragraph-small.-only-top { padding-top: $small; padding-bottom: 0; }
        &.paragraph.-only-top { padding-top: $medium; padding-bottom: 0; }
        &.paragraph-large.-only-top { padding-top: $large; padding-bottom: 0; }
    }

    .section-title {
        font-weight: 700 !important;
        font-size: var(--font-size--small) !important;

        &.-type-2 {
            font-weight: 600 !important;
            font-size: var(--font-size--medium) !important;
            padding-bottom: $medium;
        }
    }

    .header-info-link-wrapper > a {
        display: inline-block;
        position: relative;
        font-weight: normal;
        padding-right: $medium;
        margin: 0 0 $xtra-small 0 !important;

        &.sgw-button-common:before {
            display: none;
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: $small;
            height: $small;
            margin-top: math.div($small, 2) * -1;
            background: transparent url('../static/icon/info.svg') no-repeat center center / auto 100%;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0002 1.83301C9.18717 1.83301 7.41489 2.37062 5.90744 3.37787C4.39999 4.38512 3.22507 5.81675 2.53127 7.49174C1.83747 9.16673 1.65594 11.0098 2.00964 12.788C2.36333 14.5662 3.23637 16.1995 4.51835 17.4815C5.80034 18.7635 7.43368 19.6365 9.21184 19.9902C10.99 20.3439 12.8331 20.1624 14.5081 19.4686C16.1831 18.7748 17.6147 17.5999 18.622 16.0924C19.6292 14.585 20.1668 12.8127 20.1668 10.9997C20.1668 9.79589 19.9297 8.60389 19.4691 7.49174C19.0084 6.37959 18.3332 5.36907 17.482 4.51786C16.6308 3.66666 15.6202 2.99145 14.5081 2.53078C13.3959 2.07011 12.204 1.83301 11.0002 1.83301ZM11.0002 18.333C9.54977 18.333 8.13195 17.9029 6.92598 17.0971C5.72002 16.2913 4.78009 15.146 4.22505 13.806C3.67001 12.466 3.52478 10.9915 3.80774 9.56901C4.0907 8.14648 4.78913 6.83981 5.81472 5.81422C6.8403 4.78864 8.14698 4.09021 9.5695 3.80725C10.992 3.52429 12.4665 3.66952 13.8065 4.22456C15.1465 4.7796 16.2918 5.71953 17.0976 6.92549C17.9034 8.13145 18.3335 9.54928 18.3335 10.9997C18.3335 12.9446 17.5609 14.8099 16.1856 16.1851C14.8103 17.5604 12.9451 18.333 11.0002 18.333Z' fill='%234D7477'/%3E%3Cpath d='M11.0002 10.3945C10.757 10.3945 10.5239 10.4911 10.352 10.663C10.1801 10.8349 10.0835 11.0681 10.0835 11.3112V14.6662C10.0835 14.9093 10.1801 15.1425 10.352 15.3144C10.5239 15.4863 10.757 15.5829 11.0002 15.5829C11.2433 15.5829 11.4764 15.4863 11.6483 15.3144C11.8203 15.1425 11.9168 14.9093 11.9168 14.6662V11.3112C11.9168 11.0681 11.8203 10.8349 11.6483 10.663C11.4764 10.4911 11.2433 10.3945 11.0002 10.3945Z' fill='%234D7477'/%3E%3Cpath d='M11.0002 9.16634C11.5064 9.16634 11.9168 8.75594 11.9168 8.24967C11.9168 7.74341 11.5064 7.33301 11.0002 7.33301C10.4939 7.33301 10.0835 7.74341 10.0835 8.24967C10.0835 8.75594 10.4939 9.16634 11.0002 9.16634Z' fill='%234D7477'/%3E%3C/svg%3E%0A");
        }
    }

    .sgw-label.default.pension {
        background-color: #F0DBEA;
    }

    @media screen and (min-width: $desktop) {
        .h1, h1 {
            font-size: var(--font-size-eika--xlarge);
            font-size: 32px;
        }

        .h2, h2 {
            font-size: var(--font-size-eika--large);
            font-size: 32px;
        }

        .h3 {
            font-size: var(--font-size--regular);
            font-size: 26px;
        }

        .top-appending, .right-appending, .left-appending {
            opacity: 1;
            @include noneAnimation();
            @include animation-forwards();
        }

    }

    // Rettelse på feil moilbank lukk knapp postion.
    .sgw-modal-dialog__header-items button {
        position: absolute;
        right: $medium;
        top: 3px;
    }

    // SPASP-3319, midlertidig fiks ved å overstyre mobilbank lib.
    @media screen and (max-width: $desktop) {
        margin-bottom: 0;

        .sgw-modal.sgw-modal--large .custom__footer {
            padding-bottom: $large !important;
            max-height: 100px;
        }
    }
}

/******************************************************************************
font scela
******************************************************************************/
.font-scale-large #sparing-react-app {
    .header-total-value,
    .header-total-text,
    h1.main-pageName {
        text-align: left;
        padding-left: $small;
        padding-right: $small;
    }

    .sgw-content {
        .header-total-value,
        .header-total-text {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card-wrapper .text.-chart-legend-icon {
        display: none;
    }

    .chart-wrapper {
        margin-bottom: $large;

        svg {
            display: none;
        }

        .chart-text-wrapper {
            &, > div {
                position: relative;
                left: auto;
                right: auto;
                top: auto;
                bottom: auto;
                text-align: left;
                line-height: inherit;
                margin: 0;
                padding: 0;

                &:before {
                    display: none;
                }
            }

        }
    }
}