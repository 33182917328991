@use "sass:math";

@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";

.chart-wrapper {
	position: relative;
	background-color: #fff;

	&.-pie {
		max-width: 360px;
		margin-left: auto;
		margin-right: auto;

		&.-value-return {
			max-width: 180px;
			.chart-text-wrapper {
				> div:before {
					display: none;
				}
				.chart-text:first-child {
					font-size: var(--font-size--xlarge);
				}
			}
		}
	}

	.chart-text-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		> div {
			&:before {
				content: '';
				display: block;
				height: $medium;
				margin-top: $small * -1;
				background: transparent url('../../static/icon/chart-point.svg') no-repeat center center / auto 85%;
				background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 31 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.0677 0.000288524C25.8994 -0.00563229 24.835 0.670595 24.344 1.73074C23.8529 2.79088 24.0256 4.04003 24.7857 4.92729L20.6267 12.0573C19.823 11.8965 18.9887 12.08 18.3267 12.5633L12.9477 8.80029C13.3364 7.43587 12.7162 5.98568 11.4611 5.32425C10.2061 4.66282 8.65922 4.971 7.75346 6.06295C6.84771 7.1549 6.83069 8.73205 7.71267 9.84329L3.73867 16.0823C2.29922 15.7387 0.821922 16.4838 0.242539 17.8456C-0.336845 19.2073 0.150743 20.7884 1.39645 21.5873C2.64216 22.3862 4.28251 22.1699 5.27845 21.0753C6.2744 19.9807 6.33534 18.3272 5.42267 17.1623L9.39567 10.9183C10.2306 11.1203 11.1122 10.9443 11.8057 10.4373L17.1877 14.2003C16.7948 15.5785 17.4316 17.0421 18.7078 17.6941C19.984 18.3462 21.5431 18.0046 22.4297 16.8787C23.3163 15.7527 23.2828 14.157 22.3497 13.0693L26.5087 5.93929C27.6345 6.1693 28.7929 5.73599 29.4913 4.82352C30.1898 3.91106 30.3057 2.67978 29.7898 1.65301C29.2738 0.626232 28.2167 -0.015605 27.0677 0.000288524Z' fill='%2300383D'/%3E%3C/svg%3E%0A");
			}

		}

		.chart-text {
			font-weight: 700;
			&:first-child {
				font-size: var(--font-size--largest);
			}

			&.-value-swing:before {
				height: $medium !important;
				width: $medium !important;
			}

			+ .chart-text {
				margin-top: $xtra-small * -1;
			}
		}
	}

	@media screen and (max-width: $minWidth) {
		margin-left: ($small * -1.5) !important;
		margin-right: ($small * -1.5) !important;

		&.-pie.-value-return {
			margin-left: auto !important;
			margin-right: auto !important;
		}

		.chart-text-wrapper .chart-text {
			+ .chart-text {
				margin-top: math.div($xtra-small, 2) * -1;
			}
		}
	}
}

.chart-blackboard {
	position: fixed;
	left: 0;
	top: 0;
    padding: 15px;
	border: 1px solid $color-border;
	border-radius: 6px;
	background-color: rgba(255,255,255,.9);
	z-index: 5;
	white-space: nowrap;
	display: block;
    @include boxLevelShadow();

    .headline {
    	font-weight: 700;
    	font-size: 110%;
    	text-align: center;
    	padding: 0 0 5px 0;
    }

    .multiple-bar-row {
    	border-top: 1px solid $color-border;
    	padding: 1px 2px 1px 20px;
    	text-align: left;

		&.-active {
			font-weight: 700;
		}

    	&:first-child {
    		border: 0 none;
    	}

    	.color {
    		float: left;
    		margin: 4px 15px 0 -20px;
    		width: 15px;
    		height: 15px;
    		border-radius: 2px;
    	}
    }

    @media screen and (min-width: $desktop) {
    	display: block;
    }
}

.chart-line-point {
	position: absolute;
	left: 0;
	top: 0;
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
    border-radius: 100%;
    cursor: pointer;

    .inner {
    	position: absolute;
    	left: -6px;
    	right: -6px;
    	top: -6px;
    	bottom: -6px;
    	border: 2px solid #333;
    	border-radius: 100%;
    }
}

/******************************************************************************
font scela
******************************************************************************/
.font-scale-large .chart-wrapper {
	max-width: none !important;
}