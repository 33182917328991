@import '../../styles/variables';
@import '../../styles/mixin';

// small
@media screen and (max-width: 767px) {
    .fund-list-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 20px 16px 0;
        border-bottom: 1px solid lightgray;

        & .test {
            color: yellow;
        }
    }

    .ethical {
        width: 50%;
    }

    .base-info {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .detail-info {
        width: 100%;
    }

    .sprouts {
        text-align: right;
    }

    .detail-row {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .detail-item {
        display: flex;
        justify-content: space-between;
    }

    .detail-item-label {
        width: 70%;
    }

    .detail-item-value {
        text-align: right;
        width: 30%;
    }

    .small-10aar {
        display: none;
    }
}

// medium & large
@media screen and (min-width: 768px) {
    .fund-list-card {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 16px 20px 16px 0;
        border-bottom: 1px solid lightgray;
    }

    .sprouts {
        display: inline-block;
        width: 30%;
    }

    .base-info {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .detail-info {
        width: 50%;
    }

    .detail-row {
        width: 85%;
        display: flex;
        justify-content: space-between;
    }

    .detail-item {
        min-width: 100px;
    }

    .detail-item-label {
        visibility: hidden;
        display: none;
    }
}

.fund-list-card:hover {
    cursor: pointer;
    background-color: #ffffff;
}

span {
    display: block;
}

small {
    font-size: 0.8rem;
}

.fondlist-label {
    position: relative;
    top: 0px;
}

.fundlist-icon {
    width: 25px;
    height: 25px;
    color: var(--seagreen-medium);
    align-self: center;
}

.title {
    @include typography-subtitle-mixin;
    display: inline-block;
    width: 70%;
}

.subtitle {
}

.row {
    display: flex;
    justify-content: space-between;
}

.no-sprouts {
    width: 25px;
    height: 25px;
    color: var(--seagreen-pastel);
}
