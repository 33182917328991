@use "sass:math";

@import "~@eika/core-style";
@import "../../../styles/variables";
@import "../../../styles/mixin";

.view-satser-wrapper {
    text-align: center; 
    
    .view-satser-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;
        > li {
            display: table-cell;
            width: 50%;
        }
    }

    .percent-value {
        font-size: var(--font-size-eika--large);
        font-weight: 700;
    }

    .description {
        margin: $small 0 0 0 ;
    }
}

/** ************************************************************************
 ************************************************************************* */
.view-satser-wrapper {
    position: relative;
    .view-avtalekort-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

/** ************************************************************************
** ************************************************************************/
.view-legend-description {
    list-style: none;
    padding: 0;
    margin: 0 0 $large 0;
    display: table;
    width: 100%;
    > li {
        display: table-row;
        > div {
            display: table-cell;
            vertical-align: top;
            padding: $xtra-small 0 $xtra-small $xtra-small;

            &:first-child {
                padding-left: 0;
            }


            &.item-color {
                > span {
                    display: inline-block;
                    width: $medium;
                    height: $medium;
                    border-radius: 100%;
                }
            }

            &.item-value {
                text-align: right;
            }
        }
    }   
}

/** ************************************************************************
** ************************************************************************/
.view-avtale-detaljer-wrapper {
    > div {
        margin-bottom: $large * 2;        
    }

    h2 {
        font-weight: 600;
        margin-top: 0;
    }

    .card-wrapper.-list-item .flex-text-holder.-index-0 > span:first-child {
        font-weight: normal;
    }
}

/** ************************************************************************
** ************************************************************************/
.view-transaction-content {
    + .view-transaction-content {
        margin-top: $large + $small;
    }

    &.-pending {
        .collection-header {
            
        }

        .card-wrapper {
            background-color: #FEF4DC !important;

            .text.-info, .text.-amount {
                font-style: italic;
            }
        }
    }

    .card-wrapper {
        background-color: #fff !important;
    }

    .transaction-collection:first-child {
        margin-top: $large * -1;
    }

    .collection-header {
        padding: $large 0 $smaller 0;
        font-weight: 700;
        font-size: var(--font-size--medium);
    }


    @media screen and (max-width: $maxDesktop) {
        .card-wrapper {
            margin-left: $medium * -1;
            margin-right: $medium * -1;
            border-radius: 0 !important;
        }
    }   
}
