@import "~@eika/core-style";

.guideline-wrapper {
    position: relative;
    min-height: 900px;


    > .content {
    	padding: 0 0 $medium 0;
    	&.-hide {
    		display: none;
    	}
    }
}
