@use "sass:math";

@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";

$outFrame: -6px;

@mixin focusElement() {
    content: '';
    position: absolute;
    right: $outFrame + 1;
    left: $outFrame + 1;
    top: $outFrame;
    bottom: $outFrame;
    border-radius: $border-round;
    border: 1px solid var(--seagreen-medium);
}

@mixin dropdownMenuShow() {
    top: 100%;
    visibility: visible;
    z-index: 10;
    opacity: 1;
}

@mixin dropdownMenuHide() {
    top: -1000px;
    visibility: hidden;
    z-index: -10;
    opacity: 0;
}

.dropdown-menu-wrapper {
	position: relative;
    background-color: transparent;

    &.-has-search-field {
        .dropdown-menu-popup {
            padding-top: 80px;
            background: #eee none no-repeat 0 0;
            .dropdown-menu-popup-content {
                border-top: 2px solid $color-border;
            }
        }   
    }

    &.-has-error {
        .dropdown-menu-option-selected {
            border-color: $color-error;
        }
        .sgw-label.error {
            margin-top: $xtra-small;
        }
    }

    &.-disabled {
        select {
            display: none;
        }
        .dropdown-menu-option-selected:after {
            width: $medium;
            height: $medium;
            margin-top: math.div(($medium*-1), 2);
            background-size: auto 100%;
            background-image: url('../../static/icon/lock.svg');
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.14V7C17 5.67392 16.4732 4.40215 15.5355 3.46447C14.5979 2.52678 13.3261 2 12 2C10.6739 2 9.40215 2.52678 8.46447 3.46447C7.52678 4.40215 7 5.67392 7 7V8.14C6.14352 8.36114 5.38451 8.85993 4.84169 9.55836C4.29887 10.2568 4.00287 11.1154 4 12V18C4 19.0609 4.42143 20.0783 5.17157 20.8284C5.92172 21.5786 6.93913 22 8 22H16C17.0609 22 18.0783 21.5786 18.8284 20.8284C19.5786 20.0783 20 19.0609 20 18V12C19.9971 11.1154 19.7011 10.2568 19.1583 9.55836C18.6155 8.85993 17.8565 8.36114 17 8.14ZM9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7V8H9V7ZM18 18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H8C7.46957 20 6.96086 19.7893 6.58579 19.4142C6.21071 19.0391 6 18.5304 6 18V12C6 11.4696 6.21071 10.9609 6.58579 10.5858C6.96086 10.2107 7.46957 10 8 10H16C16.5304 10 17.0391 10.2107 17.4142 10.5858C17.7893 10.9609 18 11.4696 18 12V18Z' fill='%2300383D'/%3E%3Cpath d='M12 12C11.4696 12 10.9609 12.2107 10.5858 12.5858C10.2107 12.9609 10 13.4696 10 14C10.0021 14.349 10.0955 14.6914 10.2709 14.9932C10.4464 15.2949 10.6977 15.5455 11 15.72V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V15.72C13.3023 15.5455 13.5536 15.2949 13.7291 14.9932C13.9045 14.6914 13.9979 14.349 14 14C14 13.4696 13.7893 12.9609 13.4142 12.5858C13.0391 12.2107 12.5304 12 12 12Z' fill='%2300383D'/%3E%3C/svg%3E%0A");
            transform: rotate(0);
        }
    }

    &.draw-day {
        max-width: 400px;

        .dropdown-menu-option-selected {
            &:after {
                width: 30px;
                height: 30px;
                margin: -15px 10px 0 0;
                background-image: url('../../static/icon/schedule-calendar.svg');
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6C18.5304 6 19.0391 6.21071 19.4142 6.58579C19.7893 6.96086 20 7.46957 20 8V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H18ZM18 4H6C4.93913 4 3.92172 4.42143 3.17157 5.17157C2.42143 5.92172 2 6.93913 2 8V18C2 19.0609 2.42143 20.0783 3.17157 20.8284C3.92172 21.5786 4.93913 22 6 22H18C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18V8C22 6.93913 21.5786 5.92172 20.8284 5.17157C20.0783 4.42143 19.0609 4 18 4Z' fill='%2397C5CA'/%3E%3Cpath d='M20 5H4L3 9H21L20 5Z' fill='%2397C5CA'/%3E%3Cpath d='M8 7V3' stroke='%2397C5CA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 7V3' stroke='%2397C5CA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 7C7.73478 7 7.48043 6.89464 7.29289 6.70711C7.10536 6.51957 7 6.26522 7 6V4H9V6C9 6.26522 8.89464 6.51957 8.70711 6.70711C8.51957 6.89464 8.26522 7 8 7Z' fill='white'/%3E%3Cpath d='M16 7C15.7348 7 15.4804 6.89464 15.2929 6.70711C15.1054 6.51957 15 6.26522 15 6V4H17V6C17 6.26522 16.8946 6.51957 16.7071 6.70711C16.5196 6.89464 16.2652 7 16 7Z' fill='white'/%3E%3Cpath d='M8 11H10C10.5304 11 11.0391 11.2107 11.4142 11.5858C11.7893 11.9609 12 12.4696 12 13V15C12 15.5304 11.7893 16.0391 11.4142 16.4142C11.0391 16.7893 10.5304 17 10 17H8' stroke='%2397C5CA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 14H12' stroke='%2397C5CA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 11V17' stroke='%2397C5CA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-size: auto 90%;
                transform: none !important;
            }
        }

        .dropdown-menu-popup-content {
            max-height: none;
            padding: $large $medium;
        }

        .dropdown-menu-popup:before {
            background-color: var(--white);
            border-radius: 12px;
            border: 0 none;
            box-shadow: 3px 6px 12px 6px rgba(0, 0, 0, 0.2);
        }

        ul.dropdown-menu-list {
            > li {
                display: inline-block;
                width: 20%;
                position: relative;
                border: 0 none;
                &:after {
                    content: '';
                    display: block;
                    margin-top: 60%;
                }

                > a.dropdown-menu-option-btn {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 0; 

                    &:hover, &:focus, &.-focus {
                        border-color: transparent;
                        &:after {
                            display: block;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 40px;
                        height: 40px;
                        margin: -20px 0 0 -20px;
                        background-color: var(--seagreen-xtra-light);
                        border-radius: 100%;
                        display: none;
                    }

                    &, *, *:first-child {
                        text-align: center !important;
                        font-weight: normal;
                    }

                    &.-selected  {
                        &, *, *:first-child {
                            font-weight: 700;
                            text-decoration: underline;
                        }

                        &:before {
                            display: none;
                        }
                    }

                    > div {
                        position: absolute;
                        top: 15%;
                        left: 0;
                        right: 0;
                        font-size: var(--font-size--regular);
                        z-index: 2;
                        &, > span {
                            padding: 0 !important;
                            text-align: center !important;
                        }
                    }
                }
            }
        }

        .message-wrapper {
            margin: $medium 0;
        }
    }

    .dropdown-menu-label-wrapper {
        position: relative;
        z-index: 4;
        padding-bottom: $xtra_small;
        display: table;
        > * {
            display: table-cell;
            white-space: nowrap;
            &:first-child {
                width: 100%;
                padding-right: $small;
            }
        }

        label {
            white-space: normal !important;
            font-size: var(--font-size--medium);
            font-weight: 600;
            padding-bottom: $xtra_small;
        }
    }

	.dropdown-menu-content {
		position: relative;
        background-color: transparent;
	}

	.dropdown-menu-option-selected {
		position: relative;
        padding: ($xtra-small*1.5) 50px ($xtra-small*1.5) $medium;
        border: 1px solid var(--seagreen-medium);
        border-radius: $border-round;
        background-color: var(--white);
        @include transition( all, .2s, ease-in-out); 

        &.klientkonto .text.-description {
            display: none;
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            bottom: 3px;
            height: 2px;
            background-color: var(--seagreen-medium);
            display: none;
        }

		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 50%;
			width: 15px;
			height: 15px;
			margin: -10px 20px 0 0;
			background: transparent none no-repeat center center / auto 100%;
			background-image: url('../../static/icon/small-arrow-right.svg');
            transform-origin: center;
            transform: rotate(90deg);
            @include transition( all, .2s, ease-in-out); 
		}

        .dropdown-menu-option-selected-content:before {
            content: '';
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            top: 0;
            border-radius: $border-round $border-round 0 0;
            border: 1px solid var(--seagreen-medium);
            display: none;
        }

        .dropdown-menu-option-selected-lead {
            font-size: var(--font-size--small);
        }

        .flex-text-holder {
            .text {
                min-width: 1px;
                @include ellipsis();

                &:first-child {
                    width: 50%;
                    width: 100px;
                }
            } 
        }
	}

	select {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;	
		height: 100%;
		color: transparent;
	    background-color: transparent;
	    z-index: 2;
	    opacity: .01;

	    option {
	    	height: 100%;
	    }

        &:focus, &:active {
            + .dropdown-menu-search-field {
                @include dropdownMenuShow();
                z-index: 11;
            }

            + .dropdown-menu-search-field + .dropdown-menu-option-selected,
        	+ .dropdown-menu-option-selected {
                border-radius: $border-round $border-round 0 0;

                &:before {
                    display: block;
                }

        		&:after {
	            	transform: rotate(270deg);
	            }

                > .dropdown-menu-option-selected-content:before {
                    display: block;
                }
        	}

            + .dropdown-menu-search-field + .dropdown-menu-option-selected + .dropdown-menu-popup,
			+ .dropdown-menu-option-selected + .dropdown-menu-popup {
                @include dropdownMenuShow();
    	 	}
        }
	}

    .dropdown-menu-search-field {
        position: absolute;
        left: 50%;
        width: 90%;
        margin: 10px 0 0 -45%;
        @include dropdownMenuHide();
        top: 100%;
        @include transition( all, .1s, ease-in-out); 

        &:focus, &:active {
            @include dropdownMenuShow();
            z-index: 11;

            + .dropdown-menu-option-selected {
                &:before {
                    display: block;
                }


                &:after {
                    transform: rotate(270deg);
                }
            }

            + .dropdown-menu-option-selected + .dropdown-menu-popup {
                @include dropdownMenuShow();
            }
        }
    }

	.dropdown-menu-popup {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border: 1px solid $color-border;
        border-top: 2px dashed $color-border;
        border-top: 2px solid var(--seagreen-medium);
        border-top: 0 none;

        margin: -5px 0 200px 0;
        padding: 0;
        position: absolute;
        left: 4px;
        right: 4px;
        @include dropdownMenuHide();

        &:before {
            @include focusElement();
            border-width: 2px;
            bottom: math.div( $outFrame, 2);
            border-top: 0 none;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            height: 100px;
            border: 1px solid transparent;
        }

        &.-show {
            @include dropdownMenuShow();
        }
	}

    .dropdown-menu-popup-content {
        max-height: 350px;
        overflow: hidden;
        overflow-y: auto;
        background: #fff none no-repeat 0 0;
        margin: 0 -4px;
    }

 	ul.dropdown-menu-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-top: 1px solid $color-border;
            &:first-child {
                border-top: 0 none;
            }
        }

        a.dropdown-menu-option-btn {
            display: block;
            padding: $small $medium;
            background: transparent none no-repeat 0 0;
            position: relative;
            margin: 0 1px;

            &.klientkonto .text.-konto-id {
                display: none;
            }

            &.-focus, &:hover {
                background-color: var(--grey-light);
            }

            &.-selected {
                font-weight: 700;
            }

            &.-disabled {
                font-style: italic;
                &:hover {
                    cursor: default;
                    border-color: transparent;
                }
            } 

            &.-new {
                padding-left: 70px;
                &:after {
                    content: '';
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    left: 30px;
                    top: 50%;
                    margin: -15px 0 0 0;
                    background: transparent url('../../static/icon/plus-with-circle.svg') no-repeat center center / auto 80%;
                    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z' fill='%234D7477'/%3E%3Cpath d='M18.5833 13.0833H14.9167V9.41667C14.9167 9.17355 14.8201 8.94039 14.6482 8.76849C14.4763 8.59658 14.2431 8.5 14 8.5C13.7569 8.5 13.5237 8.59658 13.3518 8.76849C13.1799 8.94039 13.0833 9.17355 13.0833 9.41667V13.0833H9.41667C9.17355 13.0833 8.94039 13.1799 8.76849 13.3518C8.59658 13.5237 8.5 13.7569 8.5 14C8.5 14.2431 8.59658 14.4763 8.76849 14.6482C8.94039 14.8201 9.17355 14.9167 9.41667 14.9167H13.0833V18.5833C13.0833 18.8264 13.1799 19.0596 13.3518 19.2315C13.5237 19.4034 13.7569 19.5 14 19.5C14.2431 19.5 14.4763 19.4034 14.6482 19.2315C14.8201 19.0596 14.9167 18.8264 14.9167 18.5833V14.9167H18.5833C18.8264 14.9167 19.0596 14.8201 19.2315 14.6482C19.4034 14.4763 19.5 14.2431 19.5 14C19.5 13.7569 19.4034 13.5237 19.2315 13.3518C19.0596 13.1799 18.8264 13.0833 18.5833 13.0833Z' fill='white'/%3E%3C/svg%3E");
                }                
            }    
        }
    }

    .dropdown-menu-hint,
    .dropdown-menu-link-afte-button {
        position: relative;
    }

    .dropdown-menu-hint {
        font-style: italic;
    }
}

