@use "sass:math";

@import "~@eika/core-style";
@import "../../styles/variables";
@import "../../styles/mixin";

.radio-option-wrapper {
	position: relative;

	&.-has-error input[type="radio"] + label {
		border-color: var(--red) !important;
	}

	&.-collector,
	&.-fund-suggestion,
	&.-placement {
		&.-has-error ul.radio-option-list li.radio-option-item {
			border-color: var(--red) !important;
		}

		ul.radio-option-list li.radio-option-item {
	        border: 2px solid var(--seagreen-pastel) !important;
	        border-radius: $border-radius;
			margin: 0 0 $small 0;
	        background-color: var(--white);

			input[type="radio"] + label {
				border-radius: $border-radius $border-radius 0 0;
				border-width: 0 !important;
				border-bottom-width: 2px !important;
			}
		}
	}

	&.-fund-suggestion {
		.radio-option-information-holder .flex-text-holder.-index-0{
			font-weight: normal;
		}
		.radio-option-description-holder .flex-text-holder {
			border: 0 none;
		}
	}

	&.-basic {
		li.radio-option-item.-has-detail,		
		li.radio-option-item.-disabled {		
	        border: 2px solid var(--seagreen-pastel) !important;
	        border-radius: $border-radius;
			margin: 0 0 $small 0;
	        background-color: var(--white);

			input[type="radio"] + label {
				border-radius: $border-radius $border-radius 0 0;
				border-width: 0 !important;
			}

			.sgw-attention-message {
				margin-top: 0;
			}
		}
	}


	fieldset {
	    border: 0 none;
	    padding: 0;
	    margin: 0;

	    legend  {
	        display: block;
	        margin: 0;
            font-weight: normal;
            font-size: var(--font-size--medium);
            font-weight: 600;
            padding: 0 0 $small 0;
	    }
	}

	ul.radio-option-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li.radio-option-item.-expanded .radio-option-note-holder {
		.radio-option-note-content {
			max-height: 200px;
		}
		.radio-option-note-tool a.expander-btn:before {
			transform: rotateX(0);
		}			
	}

	li.radio-option-item.-disabled {
		label {
			cursor: default;
			&:before {
				opacity: .2;
				/*
				background-color: #333;
				border-color: #333;
				*/
			}
		}
	}

	li.radio-option-item.-has-detail,
	li.radio-option-item.-disabled {
		.sgw-attention-message {
			margin: $small;
		}

	}


	.radio-option-input-holder {
		position: relative;
	}

	input[type="radio"] {
	    opacity: .01;
	    filter: alpha(opacity=1);
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: -1;
	    margin: 8px 0 0 8px;

	    &:checked {
	        + label {
	            background-color: var(--seagreen-xtra-light);
	            &:before {
	                background-color: var(--seagreen-dark);
	            }
	        }
	    }

	    + label {
	        display: block;
	        position: relative;
	        white-space: normal;
            margin: 0 0 5px 0;      
            padding: $small $small $small ($small*3);
	        cursor: pointer;
            border: 2px solid var(--seagreen-pastel) !important;
	        border-radius:$border-radius;
            background-color: var(--white);

	        &:before {
	            content: '';
	            position: absolute;
	            left: 0;
	            top: 2px;
	            height: $small - 4;
	            width: $small - 4;
	            margin: $small 0 0 $small;
	            border: 3px solid var(--white);
	            border-radius: 100%;
                box-shadow: 0 0 0 2px var(--seagreen-dark);
	            @include transition( all, .2s, ease-in-out); 
	        }

	        &:hover, &:focus {
	            background-color: var(--seagreen-xtra-light);
		    }
	    }
	}

	.error-message-wrapper {
		margin: 0 0 $medium 0;
	}
	
	.radio-option-source-holder,
	.radio-option-note-holder,
	.radio-option-description-holder,
	.radio-option-information-holder {
		padding: $small;
	}

	.radio-option-description-holder {
		.text {
			font-weight: normal;
			padding: $xtra-small 0;
		}

		.flex-text-holder {
			border-top: 1px solid #ECE5E0;
			border-bottom: 1px solid #ECE5E0;
			+ .flex-text-holder {
				margin-top: -1px;
			}

		}
	}

	.radio-option-note-holder {
		position: relative;
		padding-bottom: $large;
		margin-bottom: $medium;

		.radio-option-note-tool {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 4px;
			text-align: center;

			a.expander-btn {
				position: relative;
				display: inline-block;
				padding: 0 ($medium + 4) 0 0;
				border-bottom: 2px solid var(--seagreen-pastel);

				&:before {
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					height: $medium;
					width: $medium;
					margin-top: math.div($medium, 2) * -1;
					background: transparent url('../../static/icon/small-arrow-up-blue.svg') no-repeat center center / auto	40%;
					background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4167 6.75011C13.4174 6.87075 13.3943 6.99034 13.3486 7.10202C13.303 7.21371 13.2358 7.31529 13.1508 7.40094C13.0656 7.48686 12.9642 7.55505 12.8525 7.60159C12.7408 7.64813 12.621 7.67209 12.5 7.67209C12.379 7.67209 12.2592 7.64813 12.1475 7.60159C12.0358 7.55505 11.9344 7.48686 11.8492 7.40094L7.00001 2.54261L2.15084 7.40094C1.97823 7.57355 1.74412 7.67052 1.50001 7.67052C1.2559 7.67052 1.02179 7.57355 0.849174 7.40094C0.676562 7.22833 0.57959 6.99422 0.57959 6.75011C0.57959 6.62924 0.603397 6.50955 0.649653 6.39788C0.695908 6.28621 0.763705 6.18474 0.849174 6.09927L6.34917 0.599273C6.43439 0.513356 6.53578 0.445161 6.64748 0.398623C6.75918 0.352085 6.879 0.328125 7.00001 0.328125C7.12102 0.328125 7.24083 0.352085 7.35254 0.398623C7.46424 0.445161 7.56563 0.513356 7.65084 0.599273L13.1508 6.09927C13.2358 6.18493 13.303 6.28651 13.3486 6.39819C13.3943 6.50988 13.4174 6.62947 13.4167 6.75011Z' fill='%2397C5CA'/%3E%3C/svg%3E%0A");
					transform-origin: 50% 50%;
					transform: rotateX(180deg);
				}
			}
		}

		.radio-option-note-content {
			overflow: hidden;
			max-height: 0;
			@include transition( all, .2s, ease-in-out); 
		}
	}

	.text.-tag {
		margin: $xtra-small $xtra-small $xtra-small 0;		
	}
}